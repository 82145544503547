import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {Row,Col,message,Menu,PageHeader,Form,Input,Button,Select} from 'antd';
import {Header} from './Header.jsx'
import {Footer} from './Footer.jsx'
import {refresh,CheckLogin} from './Common.jsx'
import {UserOutlined,SafetyOutlined} from '@ant-design/icons';
import reqwest from 'reqwest'




export class Setting extends Component {
	constructor(props) {
	    super(props);
		let token = '';
		let user_name = '';
		let role = '';
		let check_result = CheckLogin()
		if (check_result !== false){
			token = check_result.Authorization;
	    	user_name = check_result.user_name;
	    	role = check_result.role;
		}
		this.state = {
			'token' : token,
			'user_name': user_name,
			'role':role,
			'user' : {},
			'box' : 'user',
		}
	}

	componentDidMount(){
		this.fetch({...this.state.pagination});
	}

	fetch = () => {
		reqwest({
			url: '/api/user',
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					this.setState({
						'user': data.result,
					})
					console.log(data.result);
					// this.refs.user.setFieldsValue({...data.resut})
					this.refs.user.setFieldsValue({...data.result})
				}
			},
			(err,data) => {
				message.error('Network error');
			}
		);
	}

	changeMenu = (item) => {
		this.setState({'box': item.key})
	}

	changeUserInfo = (value) => {
		reqwest({
			url: '/api/user/info',
			method: 'POST',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {
				name_chs: value.name_chs, 
				'institution': value.institution, 
				'institution_type': value.institution_type, 
				'title': value.title, 
				'mobile': value.mobile, 
				'tel': value.tel
			}
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('更新成功');
					refresh(this.state.token);
					this.setState({user_name: value.name})
				}
			},
			(err,data) => {
				message.error('Network error');
			}
		);
	}

	changePassowrd = (data) => {
		reqwest({
			url: '/api/user/password',
			method: 'POST',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: data
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('更新成功');
				}
			},
			(err,data) => {
				message.error('Network error');
			}
		);
	}

	render() {
		if(! this.state.token){
			return <Redirect to="/login"/>
		}
		return (
			<div>
				<Header {...this.state} />
				<div className="max-width">
					<Row style={{marginTop:'10px'}}>
						<Col span={6}>
							<div style={{marginTop: 16, marginRight:20}}>
								<Menu defaultSelectedKeys={['user']} onSelect={this.changeMenu}>
									<Menu.Item key="user" icon={<UserOutlined />}>账号设置</Menu.Item>
									<Menu.Item key="password" icon={<SafetyOutlined />}>修改密码</Menu.Item>
								</Menu>
							</div>
						</Col>
						<Col span={18}>
							<PageHeader
								title="账号设置"
								style={{display:(this.state.box === 'user'? 'block' : 'none')}}
							>
								<Form 
									ref="user"
									style={{maxWidth:500}} 
									labelCol = {{ span: 6 }}
									wrapperCol = {{ span: 16 }}
									labelAlign = "left"
									onFinish={this.changeUserInfo}
								>
									<Form.Item name="email" label="邮箱">
										<Input disabled={true} />
									</Form.Item>

									<Form.Item name="name_chs" label="姓名" >
										<Input />
									</Form.Item>

									<Form.Item name="institution" label="单位" >
										<Input />
									</Form.Item>

									<Form.Item name="institution_type" label="单位类型" >
										<Input />
									</Form.Item>

									<Form.Item name="title" label="职称" >
										<Select >
										{
											['教授','副教授','研究员','副研究员','助理研究员','临床医生','企业研发人员','企业高管','博后','博士研究生','硕士研究生','本科生','其他'].map((item,index) => {
												return <Select.Option key={index}>{item}</Select.Option>
											})
										}
										</Select>
									</Form.Item>
									<Form.Item name="mobile" label="手机号码" >
										<Input />
									</Form.Item>
									<Form.Item name="tel" label="座机" >
										<Input />
									</Form.Item>

									<Form.Item wrapperCol = {{ offset: 6, span: 16 }}>
										<Button type="primary" htmlType="submit">保存</Button>
									</Form.Item>
								</Form>

							</PageHeader>


							<PageHeader
								title="修改密码"
								style={{display:(this.state.box === 'password'? 'block' : 'none')}}
							>
								<Form 
									style={{maxWidth:500}} 
									labelCol = {{ span: 8 }}
									wrapperCol = {{ span: 16 }}
									labelAlign = "left"
									onFinish={this.changePassowrd}
								>
									<Form.Item name="old_password" label="旧密码" rules={[{ required: true }]}>
										<Input.Password/>
									</Form.Item>

									<Form.Item name="password" label="新密码" rules={[{ required: true, min:8 }]}>
										<Input.Password/>
									</Form.Item>

									<Form.Item name="password_confirmation" label="确认密码" dependencies={['password']} 
										rules={[
											{required: true},
											({ getFieldValue }) => ({
												validator(rule, value) {
													if (!value || getFieldValue('password') === value) {
														return Promise.resolve();
													}
													 return Promise.reject('新密码与确认密码不一致!');
												}
											}),
										]}
									>
										<Input.Password/>
									</Form.Item>

									

									<Form.Item  wrapperCol = {{ offset: 8, span: 16 }}>
										<Button type="primary" htmlType="submit">保存</Button>
									</Form.Item>
								</Form>

							</PageHeader>
						</Col>
					</Row>
				</div>
				<Footer />
			</div>
		)
	}
}