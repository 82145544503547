import React, {Component} from 'react';
import {Redirect,Link} from 'react-router-dom'
import {Table,message,PageHeader,Button,Modal,Form,Input,Space,Select,Descriptions,Tag,Comment,Breadcrumb,Checkbox} from 'antd';
import {ExclamationCircleOutlined,CloseOutlined,PlusOutlined,UserOutlined} from '@ant-design/icons';
// import zhCN from 'antd/es/locale/zh_CN';
import reqwest from 'reqwest'
import moment from 'moment'; 
// import Highlighter from 'react-highlight-words';
import {Header} from '../Header.jsx'
import {Footer} from '../Footer.jsx'
import Nav from './Nav.jsx'
import {CheckLogin} from '../Common.jsx'
import BuildTitle from '../BuildTitle.jsx'

moment.locale('zh-cn');



export default class Disease extends Component {
	constructor(props) {
	    super(props);
		let token = '';
		let user_name = '';
		let role = '';
		let check_result = CheckLogin();
		if (check_result !== false){
			token = check_result.Authorization;
	    	user_name = check_result.user_name;
	    	role = check_result.role;
		}
		this.state = {
			...props,
			'L1': 'crog',
			'L2' : 'diseases',
			'token' : token,
			'user_name': user_name,
			'role': role,
			'redirect' : '',
			'disease_id' : props.match.params.disease_id,
			'disease': {},
			'diseases': [],
			'disease_column': [
				{'title':'Source', 'dataIndex':'source'},
				{'title':'基因名称', 'dataIndex':'gene_symbol'},
				{'title':'疾病ID', 'dataIndex':'disease_id'},
				{'title':'疾病英文名', 'dataIndex':'disease_name'},
				{'title':'Inheritance', 'dataIndex':'inheritance'},
				{'title':'OMIM mapping key', 'dataIndex':'OMIM_mapping_key'},
				{'title':'Evidence level', 'dataIndex':''},
				{'title':'操作', 'key':'action', render:(text,record) => {
					return (<Space>
						<Button type="link" className="btn-in-table" onClick={() => {this.openAddDisease()}}>编辑</Button>
					</Space>)
				}},
			],
			'hpo_column':[
				{'title':'HPO', 'dataIndex':'id',render:(text) => {
					return <Link to={`/crog/hpo/${text}`} target="_blank">{text}</Link>
				}},
				{'title':'中文名称', 'dataIndex':'name_chs'},
				{'title':'出现频率', 'dataIndex':'frequency'},
				{'title':'核心表型', render:(text,record) => {
					return <Checkbox checked={record['main_phenotype'] ? true : false} onChange={e => this.updateMianPhen(e, record['id'])} />
				}},
				{'title':'操作', 'key':'action', render:(text,record) => {
					return (<Space>
						<Button type="link" className="btn-in-table" onClick={() => {this.removeHPO(record['id'])}}>移除</Button>
					</Space>)
				}},
			],
			'transcripts': [],
			'hpos': [],
			'loading': true,
		}
	}
	componentDidMount(){
		if(this.state.token){
			this.fetch();
			// this.getDisease();
			this.getDiseaseHpo()
		}
	}


	fetch = () => {
		reqwest({
			url: `/api/crog/disease/${this.state.disease_id}`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){

					this.setState({
						'loading': false,
						'disease': data.result,
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	getDisease = () => {
		reqwest({
			url: `/api/crog/gene/${this.state.gene}/disease`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					
					this.setState({
						'loading': false,
						'diseases': data.results,
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	getDiseaseHpo = () => {
		reqwest({
			url: `/api/crog/disease/${this.state.disease_id}/hpo`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {

				if(data.status === 1){
					this.setState({
						'loading': false,
						'hpos': data.results,
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	

	status_view = () => {
		let status = this.state.disease['status'];
		if(!status){
			return ''
		}
		if(status === 1){ return '未校对';}
		if(status === 2){ return '校对中';}
		if(status === 3){ return '完成校对';}
		if(status === 4){ return '评审通过';}
		if(status === 9){ return '已废弃';}
		return status;
	}

	// 标签的添加和删除
	removeTag = (tag) => {
		let that = this;
		Modal.confirm({
			title: '您确信要删除该别名?',
			icon: <ExclamationCircleOutlined />,
			onOk() {
				that.postTagRemove(tag);
			},
		});
		return false;
	}
	postTagRemove = (tag) => {
		reqwest({
			url: `/api/crog/disease/${this.state.disease_id}/alias`,
			method: 'DELETE',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {'alias' : tag}
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('添加成功');
					this.fetch()
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}
	showInput = () =>{
		this.setState({
			'alias_input': true
		}, () => {
			this.refs.alias_input.focus()
		})
	}
	clearInput = () => {
		this.setState({
			'alias_input': false,
			'key_input': false
		})
	}
	addNewAlias = (e) => {
		let value = e.target.value;
		if(!value){
			message.error('请输入基因别名');
			return true;
		}
		if(this.state.gene_info['alias'].indexOf(value) !== -1){
			message.error('您输入的别名已经存在');
			return true;
		}
		reqwest({
			url: `/api/crog/gene/${this.state.gene}/alias`,
			method: 'POST',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {'alias' : value}
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('添加成功');
					this.fetch()
					this.setState({'alias_input': false})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}


	// 关键字的添加
	removeKey = (key) => {
		let that = this;
		Modal.confirm({
			title: '您确信要删除该标签?',
			icon: <ExclamationCircleOutlined />,
			onOk() {
				that.postKeyRemove(key);
			},
		});
		return false;
	}
	postKeyRemove = (key) => {
		reqwest({
			url: `/api/crog/disease/${this.state.disease_id}/key`,
			method: 'DELETE',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {'key' : key}
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('添加成功');
					this.fetch()
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}
	showInput2 = () =>{
		this.setState({
			'key_input': true
		}, () => {
			this.refs.key_input.focus()
		})
	}
	addNewKey = (e) => {
		let value = e.target.value;
		if(!value){
			message.error('请输入基因别名');
			return true;
		}
		if(this.state.disease['keys'].indexOf(value) !== -1){
			message.error('您输入的关键字已经存在');
			return true;
		}
		reqwest({
			url: `/api/crog/disease/${this.state.disease_id}/key`,
			method: 'POST',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {'key' : value}
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('添加成功');
					this.fetch()
					this.setState({'key_input': false})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}


	// 移除HPO
	removeHPO = (hpo_id) => {
		let that = this;
		Modal.confirm({
			title: '您确信要删除该HPO?',
			icon: <ExclamationCircleOutlined />,
			onOk() {
				that.postRemoveHPO(hpo_id);
			},
		});
		return false;
	}
	postRemoveHPO = (hpo_id) => {
		reqwest({
			url: `/api/crog/disease/${this.state.disease_id}/hpo`,
			method: 'DELETE',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {'id' : hpo_id}
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('删除成功');
					this.getDiseaseHpo()
					this.setState({'add_hpo': false})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	// 添加HPO表型
	openHPOModal = () => {
		this.setState({'add_hpo': true})
	}
	closeHPOModal = () => {
		this.setState({'add_hpo': false})
	}
	addHPO = () => {
		let form =this.refs.add_hpo;
		form.validateFields().then((data) => {
			if(! data['id']){
				message.error('请输入HPO ID');
				return false;
			}
			reqwest({
				url: `/api/crog/disease/${this.state.disease_id}/hpo`,
				method: 'POST',
				headers: {
					'Authorization': this.state.token,
				},
				type: 'json',
				data: {'id' : data['id']}
			}).then(
				(data) => {
					if(data.status === 1){
						message.success('添加成功');
						this.getDiseaseHpo()
						this.setState({'add_hpo': false})
					}else{
						message.error(data.msg);
						this.setState({
							'loading': false,
						})
					}
				},
				(err,data) => {
					if(err.response === 'Unauthorized.'){
						console.log('Token invalid');
						localStorage.removeItem('Authorization');
						this.setState({
							'redirect': '/login'
						})
						message.error('登录失效');
						return false;
					}
					message.error('Network error');
					this.setState({'loading': false})
				}
			);
		})
	}


	changeDisease = (value) => {
		this.setState({'this_disease_id': value})
		this.getDiseaseHpo(value);
	}

	openAddDisease = () => {
		message.error('该功能还未开发');
	}



	showTranscriptInput = () => {
		this.setState({'edit_canonical_transcript': true, 'canonical_transcript': this.state.gene_info['canonical_transcript']})
	}
	closeTranscriptInput = () => {
		this.setState({'edit_canonical_transcript': false})
	}
	canonicalTranscriptChange = (value) => {
		this.setState({'canonical_transcript': value})
	}
	postCanonicalTranscriptChange = () => {
		reqwest({
			url: `/api/crog/gene/${this.state.gene}/transcript`,
			method: 'PATCH',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {'transcript' : this.state.canonical_transcript}
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('添加成功');
					this.fetch()
					this.setState({'edit_canonical_transcript': false})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	openCommentModal = () => {
		this.setState({'add_comment_modal': true});
	}
	closeCommentModal = () => {
		this.setState({'add_comment_modal': false});
	}
	addComment = () => {
		let form =this.refs.add_comment;
		let that = this;
		form.validateFields().then((data) => {
			if(! data['comment']){
				message.error('请输入评论');
				return false;
			}

			reqwest({
				url: `/api/crog/disease/${that.state.disease_id}/comment`,
				method: 'POST',
				headers: {
					'Authorization': that.state.token,
				},
				type: 'json',
				data: data
			}).then(
				(data) => {
					if(data.status === 1){
						message.success('添加成功');
						that.fetch()
						that.setState({'add_comment_modal': false})
					}else{
						message.error(data.msg);
						that.setState({
							'loading': false,
						})
					}
				},
				(err,data) => {
					if(err.response === 'Unauthorized.'){
						console.log('Token invalid');
						localStorage.removeItem('Authorization');
						that.setState({
							'redirect': '/login'
						})
						message.error('登录失效');
						return false;
					}
					message.error('Network error');
					that.setState({'loading': false})
				}
			);


		})
	}





	openDescModal = (key) => {
		let t = {
			'OMIM_disease_desc': 'OMIM description',
			'clinical_features_chs': '疾病描述',
			'diagnosis_criteria_chs': '诊断标准',
			'therapy_chs': '治疗方法',
		}
		this.setState({'edit_desc_modal': true, 'target_key':key, 'target_title': t[key]})
	}
	closeDescModal = () => {
		this.setState({'edit_desc_modal': false})
	}
	editDesc = () => {
		let form =this.refs.edit_desc;
		let that = this;
		form.validateFields().then((data) => {
			if(! data['key']){
				message.error('请输入内容');
				return false;
			}
			reqwest({
				url: `/api/crog/disease/${that.state.disease_id}/desc`,
				method: 'PATCH',
				headers: {
					'Authorization': that.state.token,
				},
				type: 'json',
				data: {'key': this.state.target_key, 'value': data['key']}
			}).then(
				(data) => {
					if(data.status === 1){
						message.success('添加成功');
						that.fetch()
						that.setState({'edit_desc_modal': false})
					}else{
						message.error(data.msg);
						that.setState({
							'loading': false,
						})
					}
				},
				(err,data) => {
					if(err.response === 'Unauthorized.'){
						console.log('Token invalid');
						localStorage.removeItem('Authorization');
						that.setState({
							'redirect': '/login'
						})
						message.error('登录失效');
						return false;
					}
					message.error('Network error');
					that.setState({'loading': false})
				}
			);


		})
	}

	changeState = (value) => {
		reqwest({
			url: `/api/crog/disease/${this.state.disease_id}/status`,
			method: 'PATCH',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {'status': value}
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('修改成功');
					this.fetch()
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}
	


	
	updateMianPhen = (e, hpo_id) => {
		let that = this;
		if(! e.target.checked){
			Modal.confirm({
				title: '您确定移除该核心表型?',
				icon: <ExclamationCircleOutlined />,
				onOk() {
					that.setMianPhen(hpo_id, e.target.checked)
				},
			});
		}else{
			that.setMianPhen(hpo_id, e.target.checked)
		}
	}
	setMianPhen = (hpo_id, action ) => {
		reqwest({
			url: `/api/crog/disease/${this.state.disease_id}/hpo`,
			method: 'PATCH',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {'id': hpo_id, action: action}
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('修改成功');
					this.getDiseaseHpo()
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}
	
	render(){
		if(! this.state.token){
			return <Redirect to="/login"/>
		}
		if(this.state.redirect){
			return <Redirect to={this.state.redirect}/>
		}
		return (
			<div>
				<Header {...this.state} />
				<div className="max-width">
					<Nav L2={this.state.L2} />
					<Breadcrumb style={{'marginTop':'9px'}}>
						<Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
						<Breadcrumb.Item><Link to={`/crog/diseases`}>Diseases</Link></Breadcrumb.Item>
						<Breadcrumb.Item>{this.state.disease_id}</Breadcrumb.Item>
					</Breadcrumb>
					<PageHeader title={this.state.disease_id} style={{padding:0,marginTop:10}} >
						<Descriptions size="small" column={3}  style={{'width':'800px'}}>
	        				<Descriptions.Item label="创建时间">{this.state.disease['date_created']} </Descriptions.Item>
	        				<Descriptions.Item label="最后修改">{this.state.disease['date_last_update']} </Descriptions.Item>
	        				<Descriptions.Item label="状态">
	        					<Select bordered={false} 
	        						size="small"
	        						onChange={this.changeState}
	        						value={ this.state.disease['status'] ? this.state.disease['status'].toString() : ''}
	        						style={{'width':'100px'}}
	        					>
	        						<Select.Option value="1">未校对</Select.Option>
	        						<Select.Option value="2">校对中</Select.Option>
	        						<Select.Option value="3">完成校对</Select.Option>
	        						<Select.Option value="4">评审通过</Select.Option>
	        						<Select.Option value="9">已废弃</Select.Option>
	        					</Select>
	        				</Descriptions.Item>
	        			</Descriptions>
					</PageHeader>

					<PageHeader title="基本信息" style={{paddingLeft:0,paddingRight:0}} >
						<Descriptions size="small" column={3} bordered className="table_list2">
	        				<Descriptions.Item label="Source">{this.state.disease['source']} </Descriptions.Item>
	        				<Descriptions.Item label="疾病ID">{this.state.disease['disease_id']} </Descriptions.Item>
	        				<Descriptions.Item label="疾病名英文">{this.state.disease['disease_name']} </Descriptions.Item>
	        				<Descriptions.Item label="疾病名中文">{this.state.disease['disease_name_chs']} </Descriptions.Item>
	        				<Descriptions.Item label="疾病symbol">{this.state.disease['disease_symbol']} </Descriptions.Item>
	        				<Descriptions.Item label="疾病类型"> </Descriptions.Item>
	        			</Descriptions>
	        		</PageHeader>
	        		<PageHeader title="关键字" style={{paddingLeft:0,paddingRight:0}} >
						{
							this.state.disease['keys'] ?  this.state.disease['keys'].map((item,index) => {
								return <Tag key={item} >{item}  <CloseOutlined onClick={e => this.removeKey(item)} /></Tag>
							}) : ''
						}
						
						{
							this.state.key_input ? (
								<Input size="small" className="tag-input" ref="key_input" onPressEnter={this.addNewKey}  onBlur={this.clearInput} />
							) : (
								<Tag className="site-tag-plus" onClick={this.showInput2}>
									<PlusOutlined /> 关键字
								</Tag>
							)
						}
								
	        		</PageHeader>
					<PageHeader title="基因别名" style={{paddingLeft:0,paddingRight:0}} >
						{
							this.state.disease['alias'] ?  this.state.disease['alias'].map((item,index) => {
								return <Tag key={item} >{item}  <CloseOutlined onClick={e => this.removeTag(item)} /></Tag>
							}) : ''
						}
						
						{
							this.state.alias_input ? (
								<Input size="small" className="tag-input" ref="alias_input" onPressEnter={this.addNewAlias}  onBlur={this.clearInput} />
							) : (
								<Tag className="site-tag-plus" onClick={this.showInput}>
									<PlusOutlined /> 别名
								</Tag>
							)
						}
								
	        		</PageHeader>
					<PageHeader title="基因和疾病" style={{paddingLeft:0,paddingRight:0}} 
						extra={[
							<Button key="1" type="primary" onClick={this.openAddDisease} >添加疾病</Button>,
						]}
					>
						<Table 
							bordered 
							loading={this.state.loading} 
							rowKey="record_id" 
							size="small" 
							columns={this.state.disease_column} 
							dataSource={this.state.diseases}
						    locale={{emptyText:'No Data',}}
							pagination={false}
						/>
	        		</PageHeader>
	        		<PageHeader title="HPO" style={{paddingLeft:0,paddingRight:0}} 
	        			extra={[
							<Button key="1" type="primary" onClick={this.openHPOModal} >添加HPO</Button>,
						]}
	        		>
						<Table 
							bordered 
							loading={this.state.loading} 
							rowKey="id" 
							size="small" 
							columns={this.state.hpo_column} 
							dataSource={this.state.hpos}
						    locale={{emptyText:'No Data',}}
							pagination={false}
						/>
	        		</PageHeader>
	        		<PageHeader title="OMIM description" style={{paddingLeft:0,paddingRight:0}} 
	        			extra={[
							<Button key="1" type="primary" onClick={e => this.openDescModal('OMIM_disease_desc')} >修改</Button>,
						]}
	        		>
	        			<p>{this.state.disease['OMIM_disease_desc'] ? this.state.disease['OMIM_disease_desc'] : '无' }</p>
	        		</PageHeader>
	        		<PageHeader title="疾病描述" style={{paddingLeft:0,paddingRight:0}} 
	        			extra={[
							<Button key="1" type="primary" onClick={e => this.openDescModal('clinical_features_chs')} >修改</Button>,
						]}
	        		>
	        			<p>{this.state.disease['clinical_features_chs'] ? this.state.disease['clinical_features_chs'] : '无' }</p>
	        		</PageHeader>

	        		<PageHeader title="诊断标准" style={{paddingLeft:0,paddingRight:0}} 
	        			extra={[
							<Button key="1" type="primary" onClick={e => this.openDescModal('diagnosis_criteria_chs')} >修改</Button>,
						]}
	        		>
	        			<p>{this.state.disease['diagnosis_criteria_chs'] ? this.state.disease['diagnosis_criteria_chs'] : '无' }</p>
	        		</PageHeader>

	        		<PageHeader title="治疗方法" style={{paddingLeft:0,paddingRight:0}} 
	        			extra={[
							<Button key="1" type="primary" onClick={e => this.openDescModal('therapy_chs')} >修改</Button>,
						]}
	        		>
	        			<p>{this.state.disease['therapy_chs'] ? this.state.disease['therapy_chs'] : '无' }</p>
	        		</PageHeader>


	        		<PageHeader title="Comments" style={{paddingLeft:0,paddingRight:0}} 
	        			extra={[
							<Button key="1" type="primary" onClick={this.openCommentModal} >添加评论</Button>,
						]}
	        		>
	        			{
	        				this.state.disease['comments'] && this.state.disease['comments'].map((item,index) => {
	        					return <div style={{border:'1px solid #f0f0f0','paddingRight':'16px','paddingLeft':'16px'}}>
	        						<Comment content={item['comment']} author={item['email']} datetime={item['date']} avatar={<UserOutlined />}  />
	        					</div>
	        				})
	        			}
	        		</PageHeader>

				</div>
				<Modal
		        	title={<BuildTitle visible={this.state.add_comment_modal} id="add_comment_modal" title="添加评论" />}
					visible={this.state.add_comment_modal}
					onCancel={this.closeCommentModal}
					onOk={this.addComment}
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('add_comment_modal').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="add_comment"
						layout="horizontal"
						initialValues={{}}
						labelCol = {{ span: 4 }}
						wrapperCol = {{ span: 24 }}
						labelAlign="left"
					>
						<Form.Item name="comment" label="" rules={[{ required: true }]}>
							<Input.TextArea rows={5} />
						</Form.Item>
					</Form>
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.edit_desc_modal} id="edit_desc_modal" title={this.state.target_title} />}
					visible={this.state.edit_desc_modal}
					onCancel={this.closeDescModal}
					onOk={this.editDesc}
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('edit_desc_modal').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="edit_desc"
						layout="horizontal"
						initialValues={{'key': this.state.disease[this.state.target_key]}}
						labelCol = {{ span: 4 }}
						wrapperCol = {{ span: 24 }}
						labelAlign="left"
					>
						<Form.Item name="key" label="" rules={[{ required: true }]}>
							<Input.TextArea rows={5} />
						</Form.Item>
					</Form>
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.add_hpo} id="add_hpo" title="添加HPO表型" />}
					visible={this.state.add_hpo}
					onCancel={this.closeHPOModal}
					onOk={this.addHPO}
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('add_hpo').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="add_hpo"
						layout="horizontal"
						initialValues={{}}
						labelCol = {{ span: 4 }}
						wrapperCol = {{ span: 24 }}
						labelAlign="left"
					>
						<Form.Item name="id" label="" rules={[{ required: true }]}>
							<Input placeholder="HPO ID" />
						</Form.Item>
					</Form>
				</Modal>
				<Footer />
			</div>
		)
	}
}