export default {
  race : [
    "汉族", 
    "壮族", 
    "满族", 
    "回族", 
    "苗族", 
    "维吾尔族", 
    "土家族", 
    "彝族", 
    "蒙古族", 
    "藏族", 
    "布依族",
    "侗族", 
    "瑶族", 
    "朝鲜族", 
    "白族", 
    "哈尼族",
    "哈萨克族", 
    "黎族", 
    "傣族", 
    "畲族", 
    "傈僳族", 
    "仡佬族", 
    "东乡族", 
    "高山族", 
    "拉祜族",
    "水族", 
    "佤族", 
    "纳西族", 
    "羌族", 
    "土族", 
    "仫佬族", 
    "锡伯族",
    "柯尔克孜族", 
    "达斡尔族", 
    "景颇族", 
    "毛南族", 
    "撒拉族", 
    "布朗族", 
    "塔吉克族", 
    "阿昌族", 
    "普米族", 
    "鄂温克族", 
    "怒族", 
    "京族", 
    "基诺族", 
    "德昂族", 
    "保安族",
    "俄罗斯族", 
    "裕固族", 
    "乌孜别克族", 
    "门巴族", 
    "鄂伦春族", 
    "独龙族", 
    "塔塔尔族", 
    "赫哲族", 
    "珞巴族",
    "其他"
  ],

  'province': [
    '北京市', '广东省', '山东省', '江苏省', '河南省', '上海市', '河北省', '浙江省', '香港特别行政区', '陕西省', '湖南省', '重庆市', '福建省', '天津市', '云南省', '四川省', '广西壮族自治区', '安徽省', '海南省', '江西省', '湖北省', '山西省', '辽宁省', '台湾省', '黑龙江', '内蒙古自治区', '澳门特别行政区', '贵州省', '甘肃省', '青海省', '新疆维吾尔自治区', '西藏区', '吉林省', '宁夏回族自治区', '其他'
  ]
}