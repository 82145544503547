import React from 'react';
import DragM from "dragm";

export default class BuildTitle extends React.Component {
  updateTransform = transformStr => {
    this.modalDom.style.transform = transformStr;
  };
  componentDidMount() {
    const { id } = this.props;
    let doc = document.getElementById(id);
    if(doc != null){
        this.modalDom = doc;
    }else{
        this.modalDom = document.getElementsByClassName("ant-modal-wrap")[(document.getElementsByClassName("ant-modal-wrap")).length-1];
        this.modalDom.id = id;
    }
  }
  render() {
    const { title } = this.props;
    return (
      <DragM updateTransform={this.updateTransform}>
        <div>{title}</div>
      </DragM>
    );
  }
}