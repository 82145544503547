import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {Table,message,PageHeader,Button,Modal,Form,Input,Space,DatePicker,Select} from 'antd';
import {ExclamationCircleOutlined,SearchOutlined} from '@ant-design/icons';
import zhCN from 'antd/es/locale/zh_CN';
import reqwest from 'reqwest'
import moment from 'moment'; 
import Highlighter from 'react-highlight-words';
import {Header} from '../Header.jsx'
import {Footer} from '../Footer.jsx'
import {CheckLogin} from '../Common.jsx'
import BuildTitle from '../BuildTitle.jsx'

moment.locale('zh-cn');
// const relationship = {
//     'patient' : '本人',
// 	'father' : '父亲',
//     'mother' : '母亲',
//     'husband' : '丈夫',
//     'wife' : '妻子',
//     'paternal-grandfather' : '祖父',
//     'paternal-grandmother' : '祖母',
//     'maternal-grandfather' : '外祖父',
//     'maternal-grandmother' : '外祖母',
//     'paternal-uncle' : '叔伯',
//     'paternal-aunt' : '姑姑',
//     'maternal-uncle' : '舅舅',
//     'maternal-aunt' : '姨妈',
//     'brother' : '兄弟',
//     'sister' : '姐妹',
//     'fraternal-twins' : '异卵双胞胎',
//     'identical-twins' : '同卵双胞胎',
//     'son' : '儿子',
//     'daughter' : '女儿',
//     'other' : '其他'
// };
const sample_type = [
	'外周血','口腔脱落细胞','唾液','脑脊液','脐带血','血斑','头发','精液','正常组织','肿瘤组织','FFPE样本','肿瘤FFPE样本','基因组DNA','外周血游离DNA','其他'
];
export class Samples extends Component {
	constructor(props) {
	    super(props);
		let token = '';
		let user_name = '';
		let role = '';
		let check_result = CheckLogin();
		if (check_result !== false){
			token = check_result.Authorization;
	    	user_name = check_result.user_name;
	    	role = check_result.role;
		}
		this.state = {
			...props,
			'L1': 'samples',
			'token' : token,
			'user_name': user_name,
			'role': role,
			'redirect' : '',
			'list' : [],
			'loading': true,
			'filter': {},
			'pagination': {
				'pageSize': 20,
				'current' : 1,
				'total' : 0,
			},
			column : [
				{'title':'ID', 'dataIndex':'id', width:50},
				{'title':'样本姓名', 'dataIndex':'sample_name', ...this.getColumnSearchProps('sample_name')},
				{'title':'出生年月', 'dataIndex':'birthdate', render:(text,record) => {
					if(!text){
						return ''
					}
					if(text === '0000-00-00'){
						return ''
					}
					if(record['birthdate_accuracy']){
						if(record.birthdate_accuracy === 'y'){
							return moment(text, 'YYYY-MM-DD').format('YYYY');
						}
						if(record.birthdate_accuracy === 'm'){
							return moment(text, 'YYYY-MM-DD').format('YYYY-MM');;
						}
					}
					return text;
				}},
				{'title':'采样日期', 'dataIndex':'date_collected'},
				{'title':'样本类型', 'dataIndex':'sample_type'},
				{'title':'是否患病', 'dataIndex':'is_affected', width:80},
				{'title':'实验室编号1', 'dataIndex':'barcode1'},
				{'title':'实验室编号2', 'dataIndex':'barcode2'},
				{'title':'创建日期', 'dataIndex':'created_at',width:105, render:(text) => {
					let temp = text.split(' ');
					return temp[0];
				}},
				{'title':'操作', 'key':'action', width:100, render:(text,record) => {
					return (
						<span>
							<Button type="link" className="btn-in-table" onClick={ e => {this.editRecord(record)}}>编辑</Button>
							<Button type="link" className="btn-in-table" onClick={ e => {this.removeRecord(record)}}>删除</Button>
						</span>
					) 
				}},
			],
		}
	}
	componentDidMount(){
		if(this.state.token){
			this.fetch({...this.state.pagination});
		}
	}


	getRandomuserParams = (params) => {
		return {
			pageSize: params.pageSize,
			page: params.current,
			...params,
			...this.state.filter,
		};
	}

	select_search = (input, option) => {
		for(let i in option.children){
			let str = option.children[i];
			if(str === ''){
				continue;
			}
			if (str.indexOf(input) >= 0){
				return true;
			}
		}
		return false;
	}

	fetch = (params) => {
		reqwest({
			url: `/api/mgm/samples`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			data:this.getRandomuserParams(params),
			type: 'json',
		}).then(
			(data) => {

				if(data.status === 1){
					this.setState({
						'loading': false,
						'list': data.results.data,
						'pagination': {
			       			'current': params.current,
						    'pageSize': params.pageSize,
			       			'total': data.results.total,
			        	}
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	// 表格发生改变时候
	handleTableChange = (pagination) => {
		this.setState({
			'loading': true,
		})
		this.fetch({
			current : pagination.current,
			pageSize: pagination.pageSize,
		});
	}

	// 新增
	openNewModal = () => {
		this.setState({
			'new_case_modal': true,
		})
	}
	closeNewModal = () => {
		this.setState({
			'new_case_modal': false,
		})
	}	

	postDelete = (id) => {
		reqwest({
			url: `/api/mgm/sample/${id}`,
			method: 'DELETE',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('删除成功');
					this.fetch({...this.state.pagination});
				}else{
					message.error(data.msg);
				}
				this.setState({
					'loading': false,
				})
			},
			(err,data) => {
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	removeRecord = (record) => {
		let that = this;
		Modal.confirm({
			title: '您确信要删除该记录?',
			icon: <ExclamationCircleOutlined />,
			onOk() {
				that.postDelete(record.id);
			},
		});
	}

	editRecord = (record) => {
		let data = {...record};
		if(data['date_collected'] && data['date_collected'] !== '0000-00-00'){
			data['date_collected'] = moment(data['date_collected'],'YYYY-MM-DD');
		}else{
			data['date_collected'] = null;
		}
		if(data['birthdate'] && data['birthdate'] !== '0000-00-00'){
			data['birthdate_y'] = moment(data['birthdate'],'YYYY');
			data['birthdate_m'] = moment(data['birthdate'],'YYYY-MM-DD').format('MM');
			data['birthdate_d'] = moment(data['birthdate'],'YYYY-MM-DD').format('DD');
		}else{
			data['birthdate_y'] = null;
			data['birthdate_m'] = null;
			data['birthdate_d'] = null;
		}
		let other_sample_type = false;
		if(data['sample_type'] && sample_type.indexOf(data['sample_type']) === -1){
			data['other_sample_type'] = data['sample_type'];
			data['sample_type'] = '其他';
			other_sample_type = true;
		}
		this.setState({'sample_edit': true, 'edit_sample': data, 'other_sample_type': other_sample_type})
	}
	closeEditModal = () => {
		this.setState({sample_edit: false,'edit_sample': {}})
	}
	postEdit = () => {
		let form = this.refs.sample_edit;
		form.validateFields().then((data) => {
			data['birthdate_inaccuracy'] = null;
			if(data.birthdate_y){
				data.birthdate_y =  moment(data.birthdate_y).format('YYYY');
			}
			if(!data.birthdate_y && (data.birthdate_m || data.birthdate_d )) {
				message.error('出生日期格式错误');
				return false;
			}
			if(!data.birthdate_m && data.birthdate_d){
				message.error('出生日期格式错误');
				return false;
			}
			
			if(!data.birthdate_m && !data.birthdate_d){
				data['birthdate_accuracy'] = 'y';
			}

			if(data.birthdate_m && !data.birthdate_d){
				data['birthdate_accuracy'] = 'm';
			}
			if(data.birthdate_m && data.birthdate_d){
				data['birthdate_accuracy'] = 'd';
			}

			if(!data.birthdate_m){
				data.birthdate_m = '01';
			}

			if(!data.birthdate_d){
				data.birthdate_d = '01';
			}
			data['birthdate'] = data.birthdate_y+'-'+data.birthdate_m+'-'+data.birthdate_d;

			if(data.other_sample_type){
				data['sample_type'] = data.other_sample_type;
			}

			if(data.date_collected){
				data.date_collected =  moment(data.date_collected).format('YYYY-MM-DD');
			}
			reqwest({
				url: `/api/mgm/sample/${this.state.edit_sample['id']}`,
				method: 'PATCH',
				headers: {
					'Authorization': this.state.token,
				},
				type: 'json',
				data: data,
			}).then(
				(data) => {
					if(data.status === 1){
						message.success('编辑成功');
						this.fetch({...this.state.pagination});
					}else{
						message.error(data.msg);
					}
					this.setState({
						'sample_edit': false,
					})
				},
				(err,data) => {
					message.error('Network error');
					this.setState({'loading': false})
				}
			);
		});
	}


	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.tableFilter(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.tableFilter(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button onClick={() => this.fieldReset(dataIndex,clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
				record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: text => {
			return this.state.searchedColumn === dataIndex ? (
				<Highlighter
				  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
				  searchWords={[this.state.searchText]}
				  autoEscape
				  textToHighlight={text ? text.toString() : ''}
				/>
			) : ( text )
		}
	})

	tableFilter = (selectedKeys, confirm, dataIndex) => {
		confirm();
		let filter = this.state.filter;
		filter[dataIndex] = selectedKeys[0];
		this.setState({'filter': filter});
		this.fetch({
			current : 1,
			pageSize: this.state.pagination.pageSize,
		});
	}

	fieldReset = (dataIndex, clearFilters) => {
		clearFilters();
		let filter = this.state.filter;
		delete(filter[dataIndex]);
		this.fetch({
			current : 1,
			pageSize: this.state.pagination.pageSize,
		});
	}
	
	render(){
		if(! this.state.token){
			return <Redirect to="/login"/>
		}
		if(this.state.redirect){
			return <Redirect to={this.state.redirect}/>
		}
		return (
			<div>
				<Header {...this.state} />
				<div className="max-width">
					<PageHeader title="样本列表" style={{padding:0,marginTop:10}} >
						<Table 
							bordered 
							loading={this.state.loading} 
							rowKey="id" 
							size="small" 
							columns={this.state.column} 
							dataSource={this.state.list}
							onChange={this.handleTableChange}
							pagination={{  //分页
						        total: this.state.pagination.total, //数据总数量
						        pageSize: this.state.pagination.pageSize,  //显示几条一页
						        defaultPageSize: 10, //默认显示几条一页
						        showSizeChanger: true,  //是否显示可以设置几条一页的选项
						        showTotal:  () => {return '共' + this.state.pagination.total + '条记录'; }
						    }}
						    locale={{emptyText:'No Data',}}
						/>
					</PageHeader>
				</div>
				<Modal
		        	title={<BuildTitle visible={this.state.sample_edit} id="sample_edit" title="编辑样本" />}
					visible={this.state.sample_edit}
					onCancel={this.closeEditModal}
					onOk={this.postEdit}
					width="600px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('sample_edit').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="sample_edit"
						layout="horizontal"
						initialValues={this.state.edit_sample}
						labelCol = {{ span: 4 }}
						wrapperCol = {{ span: 20 }}
						labelAlign="left"
					>
						<Form.Item name="sample_name" label="姓名" >
							<Input value="" />
						</Form.Item>

						<Form.Item  label="出生日期" >
							<Form.Item name="birthdate_y"  style={{ display: 'inline-block', width: 'calc(33% - 12px)',marginBottom:0 }}>
								<DatePicker placeholder="年" format="YYYY" mode="year" picker="year" locale={zhCN.DatePicker}  />
							</Form.Item>
							<span style={{ display: 'inline-block', width: '18px', lineHeight: '32px', textAlign: 'center' }}>
						       -
						    </span>
							<Form.Item name="birthdate_m" style={{ display: 'inline-block', width: 'calc(33% - 12px)' ,marginBottom:0}}>
								<Select placeholder="月">
									<Select.Option value=""></Select.Option>
									<Select.Option value="01">1月</Select.Option>
									<Select.Option value="02">2月</Select.Option>
									<Select.Option value="03">3月</Select.Option>
									<Select.Option value="04">4月</Select.Option>
									<Select.Option value="05">5月</Select.Option>
									<Select.Option value="06">6月</Select.Option>
									<Select.Option value="07">7月</Select.Option>
									<Select.Option value="08">8月</Select.Option>
									<Select.Option value="09">9月</Select.Option>
									<Select.Option value="10">10月</Select.Option>
									<Select.Option value="11">11月</Select.Option>
									<Select.Option value="12">12月</Select.Option>
								</Select>
							</Form.Item>
							<span style={{ display: 'inline-block', width: '18px', lineHeight: '32px', textAlign: 'center' }}>
						       -
						    </span>
							<Form.Item name="birthdate_d" style={{ display: 'inline-block', width: 'calc(33% - 12px)',marginBottom:0 }}>
								<Input placeholder="日, 格式:01"/>
							</Form.Item>
						</Form.Item>
						<Form.Item name="date_collected" label="采样日期" >
							<DatePicker locale={zhCN.DatePicker}  />
						</Form.Item>
						<Form.Item label="样本类型" >
							<Form.Item name="sample_type" style={{ display: 'inline-block', width: 'calc(50% - 12px)' ,marginBottom:0}} >
								<Select
									showSearch
									placeholder=""
									optionFilterProp=""
									filterOption={this.select_search}
									onChange={(v) => {
										if(v === '其他'){
											this.setState({'other_sample_type': true})
										}else{
											this.setState({'other_sample_type': false})
										}
									}}
								>
									{
										sample_type.map((item,index) => {
											return <Select.Option key={index} value={item}>{item}</Select.Option>
										})
									}
								</Select>
							</Form.Item>
							{
								this.state.other_sample_type ? (
									<Form.Item name="other_sample_type" style={{ display: 'inline-block', width: 'calc(50% - 12px)', marginLeft:'24px' ,marginBottom:0}} >
										<Input placeholder="请填写其他类型" />
									</Form.Item>
								) : ''
							}
							

						</Form.Item>
						<Form.Item name="barcode1" label="实验室编号1" >
							<Input value="" />
						</Form.Item>
						<Form.Item name="barcode2" label="实验室编号2" >
							<Input value="" />
						</Form.Item>
					</Form>
				</Modal>
				<Footer />
			</div>
		)
	}
}