import React, {Component} from 'react';
import {Row,Col} from 'antd';
import {Form,Input,Button,Checkbox,message} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import {UserOutlined,LockOutlined} from '@ant-design/icons';
import reqwest from 'reqwest';
import 'whatwg-fetch'
import 'antd/dist/antd.css'
import '../css/login.css'

import { Redirect,Link } from 'react-router-dom'
import {CheckLogin} from '../Common.jsx'

export class Login extends Component {
	constructor(props) {
	    super(props);
		let redirect_to = this.redirect();	
	    this.state = {
	    	'redirect_to': redirect_to,
	    	'loading': false,
	    }
	}

	handleLoginClick = (data) => {
		this.setState({
			loading:true
		})
		reqwest({
			url: '/api/login',
			method: 'POST',
			type: 'json',
			data: {email:data.email, password:data.password, remember:data.remember, 'domain': 'http://mgm.clabee.com'},
		}).then(
			(data) => {
				if(data.status === 1){
					console.log('Login success!');
					let result = CheckLogin(data.token);
					if(result !== false && (result.role === 'admin' || result.role === 'supadmin') ){
						localStorage.setItem('Authorization', data.token);
						let redirect_to = this.redirect(data.token);	
						this.setState({
							'redirect_to': redirect_to,
							'loading':false
						})
					}else{
						message.error('非法登录');
						this.setState({
							'loading':false
						})
					}
				}else{
					message.error(data.msg);
					this.setState({
						'loading':false
					})
				}
			},
			(err,data) => {
				message.error('请求失败');
			}
		);
	}

	redirect = (token = '') => {
		let check_result = CheckLogin(token);
		let redirect_to = '';
		if(['admin'].indexOf(check_result.role) > -1){
			redirect_to = '/cases';
		}
		if(['supadmin'].indexOf(check_result.role) > -1){
			redirect_to = '/users';
		}
		
		return redirect_to;
	}


	render() {
		if(this.state.redirect_to){
			return <Redirect to={this.state.redirect_to}/>
		}
		return (
			<div>
        		<div className="login-body">
        			<div className="max-width">

						<Row justify="center" align="middle" style={{marginTop:'12%'}}>
			    			<Col span={8}>
				    			<div style={{textAlign:'center', marginBottom:40,fontSize:'35px','color':'#1890ff'}}>
									MGM
								</div>
		    					<Form
							      name="normal_login"
							      className="login-form"
							      initialValues={{ remember: true }}
							      onFinish={this.handleLoginClick}
							      locale={zhCN}
							    >
							    	<Form.Item
							    		name="email"
							    		rules={[{ required: true, message: '请输入您邮箱!' }]}
							    		>
							        	<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="邮箱" />
							    	</Form.Item>
							    	<Form.Item
							        	name="password"
							        	rules={[{ required: true, message: '请输入密码!' }]}
							      		>
							        	<Input
								          	prefix={<LockOutlined className="site-form-item-icon" />}
								          	type="password"
								          	placeholder="密码"
							        	/>
							      	</Form.Item>
								    <Form.Item>
								    	<Form.Item name="remember" valuePropName="checked" noStyle>
								          <Checkbox>记住我</Checkbox>
								        </Form.Item>

								        <Link className="login-form-forgot" to="/password/reset">
								          忘记密码
								        </Link>
								    </Form.Item>

							      <Form.Item>
							        <Button loading={this.state.loading} type="primary" htmlType="submit" className="login-form-button">
							          登录
							        </Button>
							      </Form.Item>
							    </Form>
			    			</Col>
				    	</Row>
				    </div>
			    </div>
		    </div>
		)
	}
}