import React, {Component} from 'react';
import {Redirect,Link} from 'react-router-dom'
import {message,Button,Form,Radio,PageHeader,Modal,Breadcrumb,Descriptions,Input,Select,Tooltip,Empty,Checkbox,Image,Alert,
	DatePicker,Row,Col,Table,Upload,Space} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import {Header} from '../Header.jsx'
import {Footer} from '../Footer.jsx'
import {CheckLogin,autoCalculateAge,ageView} from '../Common.jsx'
import {UploadOutlined,ExclamationCircleOutlined,PlusOutlined,CloseCircleOutlined,QuestionCircleOutlined
} from '@ant-design/icons';
import reqwest from 'reqwest'
import axios from 'axios'
import moment from 'moment'; 
// import Zmage from 'react-zmage'
// import Viewer from 'react-viewer' 
import base from '../tools/base.js';
import '../css/test.css'
import BuildTitle from '../BuildTitle.jsx'


const relationship = {
    'patient' : '本人',
	'father' : '父亲',
    'mother' : '母亲',
    'husband' : '丈夫',
    'wife' : '妻子',
    'paternal-grandfather' : '祖父',
    'paternal-grandmother' : '祖母',
    'maternal-grandfather' : '外祖父',
    'maternal-grandmother' : '外祖母',
    'paternal-uncle' : '叔伯',
    'paternal-aunt' : '姑姑',
    'maternal-uncle' : '舅舅',
    'maternal-aunt' : '姨妈',
    'brother' : '兄弟',
    'sister' : '姐妹',
    'fraternal-twins' : '异卵双胞胎',
    'identical-twins' : '同卵双胞胎',
    'son' : '儿子',
    'daughter' : '女儿',
    'other' : '其他'
};

const sample_type = [
	'外周血','口腔脱落细胞','唾液','脑脊液','脐带血','血斑','头发','精液','正常组织','肿瘤组织','FFPE样本','肿瘤FFPE样本','基因组DNA','外周血游离DNA','其他'
];

const date_key = [
	'date_sample_collected', // 
	'date_sample_received', 
	'date_expr_start',
	// 'date_expr_done',
	// 'date_analysis_done',
	'date_reported',
];

const phen_table_column = [
	{'title':'HPO', 'dataIndex':'hpo', render:(text) => {return 'HP:'+text}},
	{'title':'名称/描述', 'dataIndex':'name_chs', render:(text,record) => {
		return (
			<div>
				<div>{record['name']} {record['name_chs']}</div>
				<div>{record['definition_chs'] ? record['definition_chs'] : record['definition']}</div>
			</div>
		)
	}},
];

const conclusion = {
	'1' : '阳性，检测到能解释患者临床表现的致病或疑似致病变异',
	'2' : '检测到和患者表型相关的变异，但不能解释患者的临床表现',
	'3' : '未检测到和患者表型相关的变异',
	'9' : '其他'
};

const conclusion_color = {
	'1' : 'red',
	'2' : 'gray',
	'3' : '#50BC80',
	'9' : 'gray'
};


export class MgmTest extends Component {
	constructor(props) {
	    super(props);
		let token = '';
		let user_name = '';
		let role = '';
		let check_result = CheckLogin()
		if (check_result !== false){
			token = check_result.Authorization
	    	user_name = check_result.user_name
	    	role = check_result.role;
		}

		this.state = {
			'token' : token,
			'user_name' : user_name,
			'role': role,
			'loading' : false,
			'redirect' : '',
			'id' : props.match.params.id,
			'test':{},
			// 'test_patient': {},
			'patient':{},
			'doctor':{},
			'sale':{},
			'media': [],

			// 表型搜索需要的字段
			'search_phen' : [],
			'selected_phen' : [],
			'selectedPhen' : [], //已经所选择的表型详细信息

			'patient_list': [],
			'product_list': [],
			'doctor_list': [],
			'sample_list': [],
			'sale_list': [],
			'samples' : [],

			'phen_on_show': [
				{'title':'HPO', 'dataIndex':'hpo', render:(text,record) => {
					return <Button type="link" className="btn-in-table" onClick={ e => {this.viewHPO(record)}}>HP:{text}</Button>
				}},
				{'title':'中文名称', 'dataIndex':'name_chs'},
				{'title':'英文名称', 'dataIndex':'name'},
				{'title':'中文描述', 'dataIndex':'definition_chs'},
				{'title':'操作', 'key':'action', render:(text,record) => {
					return <Button type="link" className="btn-in-table" onClick={ e => {this.removePhen(record)}}>删除</Button>
				}},
			],

			hpo_view:{},

		}
	}

	componentDidMount(){
		this.fetch();
		this.dataForTestCreate();
	}

	sample_column = [
		{'title':'样本名称', 'dataIndex':'sample_name'},
		{'title':'亲缘关系', 'dataIndex':'relationship', render:(text) => {
			return relationship[text];
		}},
		{'title':'样本类型', 'dataIndex':'sample_type'},
		{'title':'是否患病', 'dataIndex':'is_affected'},
		{'title':'是否检测', 'dataIndex':'do_test'},
		{'title':'操作', 'key':'action', render:(text,record) => {
			return (<Space>
				<Button type="link" className="btn-in-table" onClick={() => {this.openEditSample(record)}}>编辑</Button>
				<Button type="link" className="btn-in-table" onClick={() => {this.removeSample(record)}}>移除</Button>
			</Space>)
		}},
	]


	file_column = [
		{'title':'文件名称', 'dataIndex':'file_name',render:(text,record)=>{
			return <Button type="link" className="btn-in-table" onClick={e=> {this.downloadFile(record['id'], record['file_name'])}}>{text}</Button>
		}},
		{'title':'结论', 'dataIndex':'conclusion', render:(text)=> {
			return <div>{conclusion[text] ? <span style={{color:conclusion_color[text]}}>{conclusion[text]}</span> : text}</div>
		}},
		{'title':'备注', 'dataIndex':'comment'},
		{'title':'上传日期', 'dataIndex':'created_at'},
		{'title':'上传人', 'dataIndex':'name_chs'},
		
		{'title':'操作', 'key':'action', render:(text,record) => {
			return (
				<span>
			 		<Button type="link" className="btn-in-table" onClick={e=> {this.updateFile(record['id'])}}>编辑</Button>
			 		<Button type="link" className="btn-in-table" onClick={e=> {this.removeFile(record['id'])}}>移除</Button>
			 	</span>
			)
		}},
	]

	select_search = (input, option) => {
		for(let i in option.children){
			let str = option.children[i];
			str += '';
			if(str === ''){
				continue;
			}
			if(str.indexOf(input) >= 0){
				return true;
			}
		}
		return false;
	}

	getRandomuserParams = (params) => {
		return {
			pageSize: params.pageSize,
			page: params.current,
			...params,
		};
	}

	fetch = () => {
		reqwest({
			url: `/api/mgm/test/${this.state.id}`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					let patient = data.patient;
					if(patient['birthdate'] !== '0000-00-00' && patient['birthdate'] !==  null){
						patient['birthdate'] = moment(patient['birthdate'],"YYYY-MM-DD");
					}else{
						patient['birthdate'] = '';
					}
					this.setState({
						'test': data.result,
						// 'test_patient': data.test_patient,
						'patient': patient,
						'doctor': data.doctor,
						'sale': data.sale,
						'samples': data.samples,
						'files': data.files,
						'HPO': data.HPO,
						'media': data.media,
					})
					let date_init = {};
					for (let i in date_key){
						let key = date_key[i];
						if(data.result[key] === null || data.result[key] === ''){
							date_init[key] = null;
						}else{
							date_init[key] = moment(data.result[key],'YYYY-MM-DD');
						}
					}
					this.refs.test_date.setFieldsValue(date_init);
					if(data.result['user_id']){
						this.getDoctorSale(data.result['user_id']);
					}
					
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}



	dataForTestCreate = () => {
		reqwest({
			url: `/api/mgm/dataForTestCreate`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					this.setState({
						'patient_list': data.patients,
						'product_list': data.products,
						'doctor_list': data.doctors,
						'sample_list': data.samples,
					});
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	updateDate = (values) => {
		let data = {};
		for (let i in date_key){
			let key = date_key[i];
			let v = values[key];
			if(v === null || v === undefined){
				data[key] = null;
				continue;
			}
			data[key] = moment(v).format('YYYY-MM-DD');
		}

		reqwest({
			url: `/api/mgm/test/${this.state.id}`,
			method: 'PUT',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: data
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('修改成功');
					this.fetch();
				}else{
					message.error(data.msg);
					this.setState({'loading':false});
				}
			},
			(err,data) => {
				message.error('请求失败');
				this.setState({'loading':false});
			}
		);
	}

	openUploadModal = () => {
		this.setState({'upload_modal': true});
	}
	closeUploadModal = () => {
		this.setState({'upload_modal': false});
	}
	reportSave = () =>{
		if(this.state.tempFile === undefined){
			message.error('请选择文件');
			return false;
		}
		let form =this.refs.new_report;
		form.validateFields().then((data) => {
			let conclusion = data.conclusion;
			
			if(!conclusion){
				message.error('请填写结论');
				return false;
			}
			data['comment'] = (data['comment'] === undefined || data['comment'] === null) ? '' : data['comment'];

			let report = {
				...data,
				'file': this.state.tempFile,
			}
			this.uploadFile(report);
		});
	}

	uploadFile = (report) => {
		let headers = {Authorization: this.state.token};
		let that = this;
		const formData = new FormData();
		async function postData() {
		    formData.append('data', report.file);
		    formData.append('id', that.state.id);
		    formData.append('conclusion', report.conclusion);
		    formData.append('comment', report.comment);

			await axios.post('/api/mgm/test/report', formData, {headers:headers})
			.then(({ data: response }) => {
				that.refs.new_report.setFieldsValue({'conclusion':''});
				that.setState({
					'upload_modal':false,
					'tempFile':undefined
				})
				if(response.status === 0){
					message.error(response.msg)
				}else{
					let msg = '上传成功'
					if(!response.sms_to_doctor){
						msg += ' ,发送给医生的短信未发送成功';
					}
					if(!response.sms_to_sale){
						msg += ' ,发送给业务员的短信未发送成功';
					}
					message.success(msg, 6)
				}
				that.fetch();
			}).catch(
			);
		}
		postData();
	}

	downloadFile = (file_id, file_name) => {
		let headers = {Authorization: this.state.token};
		axios.get(`/api/test/${this.state.id}/report/${file_id}`, {headers:headers, responseType: 'blob'})
		.then(res => {
			if(res.data.status === 0){
				message.error('文件下载失败');
				return false;
			}
			let re = /pdf$/i;
			if(re.test(file_name)){
				let url = window.URL.createObjectURL(new Blob([res.data],{type: 'application/pdf;chartset=UTF-8'}));
				window.open(url);
				return true;
			}
			let url = window.URL.createObjectURL(new Blob([res.data]));
			let link = document.createElement("a");
			link.style.display = "none";
			link.href = url;	
			link.setAttribute("download", file_name);
			document.body.appendChild(link);
			link.click();
		}).catch( () =>{
			message.error('文件下载失败');
		});
	}

	removeFile = (file_id) => {
		let that = this;
		Modal.confirm({
			title: '警告',
			icon: <ExclamationCircleOutlined />,
			content: '您确定要删除该文件?',
			onOk() {
				reqwest({
					url: `/api/test/${that.state.id}/report/${file_id}`,
					method: 'DELETE',
					headers: {
						'Authorization': that.state.token,
					},
					type: 'json',
				}).then(
					(data) => {
						if(data.status === 1){
							message.success('删除成功');
							that.fetch();
						}else{
							message.error(data.msg);
						}
					},
					(err,data) => {
						message.error('请求失败');
					}
				);
			},
		});
	}


	publishReport = (file_id) => {
		let that = this;
		Modal.confirm({
			title: '注意',
			icon: <ExclamationCircleOutlined />,
			content: '您发布该报告后业务员和医生均可以查看该报告?',
			onOk() {
				reqwest({
					url: `/api/test/${that.state.id}/report/${file_id}`,
					method: 'PUT',
					headers: {
						'Authorization': that.state.token,
					},
					type: 'json',
					data:{'status':'已发布'}
				}).then(
					(data) => {
						if(data.status === 1){
							message.success('发布成功');
							that.fetch();
						}else{
							message.error(data.msg);
						}
					},
					(err,data) => {
						message.error('请求失败');
					}
				);
			},
		});
	}

	cancelPublish = (file_id) => {
		let that = this;
		Modal.confirm({
			title: '注意',
			icon: <ExclamationCircleOutlined />,
			content: '取消后业务员和医生将无法再查看该报告?',
			onOk() {
				reqwest({
					url: `/api/test/${that.state.id}/report/${file_id}`,
					method: 'PUT',
					headers: {
						'Authorization': that.state.token,
					},
					type: 'json',
					data:{'status':'未发布'}
				}).then(
					(data) => {
						if(data.status === 1){
							message.success('取消成功');
							that.fetch();
						}else{
							message.error(data.msg);
						}
					},
					(err,data) => {
						message.error('请求失败');
					}
				);
			},
		});
	}

	
	openEditModal = (key) => {
		let data = {};
		data[key] = true;
		this.setState(data);
	}
	closeEditModal = (key) => {
		let data = {};
		data[key] = false;
		this.setState(data);
	}
	saveAgeUnit = (value) => {
		this.setState({'age_unit': value})
	}
	// testPatientSave = (data, callback) => {
	// 	let that = this;
	// 	reqwest({
	// 		url: `/api/mgm/test/patient/${this.state.test_patient.id}`,
	// 		method: 'PUT',
	// 		headers: {
	// 			'Authorization': this.state.token,
	// 		},
	// 		type: 'json',
	// 		data: data
	// 	}).then(
	// 		(data) => {
	// 			if(data.status === 1){
	// 				that.fetch();
	// 				// that.setState(state);
	// 				if(callback !== undefined){
	// 					callback();
	// 				}
	// 				message.success('操作成功');

	// 			}else{
	// 				message.error(data.msg);
	// 			}
	// 		},
	// 		(err,data) => {
	// 			message.error('Network error');
	// 		}
	// 	);
	// }
	testSave = (data, callback) => {
		let that = this;
		reqwest({
			url: `/api/mgm/test/${this.state.test.id}`,
			method: 'PUT',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: data
		}).then(
			(data) => {
				if(data.status === 1){
					that.fetch();
					if(callback !== undefined){
						callback();
					}
					message.success('操作成功');

				}else{
					message.error(data.msg);
				}
			},
			(err,data) => {
				message.error('Network error');
			}
		);
	}
	patientSave = (data, callback) => {
		let that = this;
		reqwest({
			url: `/api/patient/${this.state.patient.id}`,
			method: 'PUT',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: data
		}).then(
			(data) => {
				if(data.status === 1){
					that.fetch();
					if(callback !== undefined){
						callback();
					}
					message.success('操作成功');

				}else{
					message.error(data.msg);
				}
			},
			(err,data) => {
				message.error('Network error');
			}
		);
	}
	editSave = (key) => {
		let that = this;
		let form =this.refs[key];
		if(form === undefined){
			return false;
		}
		let state = {};
		state[key] = false;
		form.validateFields().then((data) => {
			if(key === 'edit_base'){
				data['age_unit'] = that.state.age_unit;
				data['birthdate'] = moment(data['birthdate']).format("YYYY-MM-DD");
				this.patientSave(data,()=>{
					this.setState(state);
				})
				return true;
			}
			if(key === 'edit_test_base'){
				this.testSave(data, ()=>{
					this.setState(state);
				});
				return true;
			}

			if(key === 'edit_clinical'){
				let re = /^m?[0-9]+$/
				if(data.age_onset !== undefined && data.age_onset !== "" && data.age_onset != null && !re.test(data.age_onset)){
					message.error('起病年龄格式错误')
					return true;
				}
			}
			this.patientSave(data, ()=>{
				this.setState(state);
			});
		})
	}


	edit_base = (data) => {
		// const selectAfter = (
		// 	<Select defaultValue="岁" value={this.state.age_unit} className="select-after" onChange={this.saveAgeUnit}>
		// 		<Select.Option value="岁">岁</Select.Option>
		// 		<Select.Option value="月">月</Select.Option>
		// 		<Select.Option value="天">天</Select.Option>
		// 		<Select.Option value="孕周">孕周</Select.Option>
		// 	</Select>
		// );
		return (
			<Form
				style={{marginTop:'20px'}}
				initialValues={data}
				ref="edit_base"
				layout="horizontal"
				labelCol = {{ span: 3 }}
				wrapperCol = {{ span: 21 }}
				labelAlign="left"
			>
				<Form.Item
					name="name"
					label="姓名"
					rules={[{ required: true }]}
					>
					<Input value="" />
				</Form.Item>
				<Form.Item name="ID_number" label="身份证号码" >
					<Input value="" />
				</Form.Item>
				<Form.Item name="gender" label="性别" >
					<Radio.Group>
						<Radio value="男">男</Radio>
						<Radio value="女">女</Radio>
						<Radio value="不明确">不明确</Radio>
					</Radio.Group>
				</Form.Item>

				<Form.Item name="birthdate" label="出生日期" >
					<DatePicker locale={zhCN.DatePicker}  />
				</Form.Item>

				<Form.Item name="birthplace" label="籍贯" >
					<Select>
						{
							base.province.map((item,index) => {
								return <Select.Option key={index} value={item}>{item}</Select.Option>
							})
						}
					</Select>
				</Form.Item>
				

				
				<Form.Item name="race" label="民族" >
					<Select>
						{
							base.race.map((item,index) => {
								return <Select.Option key={index} value={item}>{item}</Select.Option>
							})
						}
					</Select>
				</Form.Item>
				<Form.Item name="country" label="国籍" >
					<Input value="中国" />
				</Form.Item>
				<Form.Item name="contact_name" label="联系人" >
					<Input value="" placeholder="如果是病人本人可不填" />
				</Form.Item>
				<Form.Item name="tel" label="固定电话" >
					<Input value="" placeholder="" />
				</Form.Item>
				<Form.Item name="mobile" label="手机号码" >
					<Input value="" placeholder="" />
				</Form.Item>
				<Form.Item name="address" label="地址" >
					<Input.TextArea value="" placeholder="" />
				</Form.Item>
			</Form>
		)
	}

	edit_clinical = (data) => {
		return (
			<Form
				style={{marginTop:'20px'}}
				initialValues={data}
				ref="edit_clinical"
				layout="horizontal"
				labelCol = {{ span: 3 }}
				wrapperCol = {{ span: 21 }}
				labelAlign="left"
			>
				<Form.Item name="clinical_abstract" label="病历摘要" >
					<Input.TextArea value="" placeholder="填写患者的主要症状，发现的时间和发展变化的过程；填写患者各项检查的结果；填写患者治疗的情况" />
				</Form.Item>
				<Form.Item name="clinical_phenotypes" label="主要症状" >
					<Input.TextArea value="" placeholder="填写患者的主要的症状；生化、核磁、免疫等检查中的异常结果" />
				</Form.Item>
				<Form.Item name="age_onset" label="起病年龄" >
					<Input value="" placeholder="填写岁数，如果不足一岁，填写几个月（30岁填写30，6个月填写m6)" />
				</Form.Item>

				
				<Form.Item name="diagnosis" label="临床诊断" >
					<Input maxLength="20" placeholder="字数不能超过20个" />
				</Form.Item>
			</Form>
		)
	}

	edit_history = (data) => {
		return (
			<Form
				style={{marginTop:'0px'}}
				initialValues={data}
				ref="edit_history"
				layout="vertical"
				labelCol = {{ span: 12 }}
				wrapperCol = {{ span: 24 }}
				labelAlign="left"
			>
				<Form.Item name="family_history"
					label={
						<span>
							有无家族史&nbsp;
							<Tooltip title="三代以内的亲属有无患类似疾病的个体">
								<QuestionCircleOutlined />
							</Tooltip>
						</span>
					}
				>
					<Radio.Group>
						<Radio value="有">有</Radio>
						<Radio value="无">无</Radio>
						<Radio value="未知/不明确">未知/不明确</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item name="is_consanguineous" label="父母是否近亲结婚" >
					<Radio.Group>
						<Radio value="是">是</Radio>
						<Radio value="否">否</Radio>
						<Radio value="未知/不明确">未知/不明确</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item name="family_history_text" label="家族史详情" >
					<Input.TextArea value="" placeholder="" />
				</Form.Item>
			</Form>
		)
	}

	edit_casual = (data) => {
		return (
			<Form
				style={{marginTop:'20px'}}
				initialValues={data}
				ref="edit_casual"
				layout="horizontal"
				labelCol = {{ span: 4 }}
				wrapperCol = {{ span: 20 }}
				labelAlign="left"
			>
				<Form.Item name="casual_gene_found" label="是否找到致病基因" >
					<Radio.Group>
						<Radio value="是">是</Radio>
						<Radio value="否">否</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item name="causal_gene" label="致病基因" >
					<Input />
				</Form.Item>
				<Form.Item name="disase_name" label="疾病名" >
					<Input />
				</Form.Item>
			</Form>
		)
	}

	edit_test_base = (data) => {
		data['product_id'] = parseInt(data['product_id']);
		return (
			<Form
				style={{marginTop:'20px'}}
				initialValues={data}
				ref="edit_test_base"
				layout="horizontal"
				labelCol = {{ span: 4 }}
					wrapperCol = {{ span: 20 }}
					labelAlign="left"
			>
				<Form.Item name="product_id" label="检测项目">
					<Select
						showSearch
						placeholder="请选择一个项目"
						optionFilterProp=""
						filterOption={this.select_search}
					>
						{
							this.state.product_list.map((item,index) => {
								return <Select.Option key={index} value={item['id']}> {item['product_sn']} {item['product_name']}</Select.Option>
							})
						}
					</Select>
				</Form.Item>

				<Form.Item name="user_id" label="医生">
					<Select
						showSearch
						placeholder="请选择一个医生"
						optionFilterProp=""
						filterOption={this.select_search}
						onChange={this.getDoctorSale}
					>
						{
							this.state.doctor_list.map((item,index) => {
								return <Select.Option key={item['id']} value={item['id']}>{item['name_chs']} {item['institution']}</Select.Option>
							})
						}
					</Select>
				</Form.Item>

				<Form.Item name="saleman_id" label="业务员">
					<Select
						showSearch
						placeholder="请选择一个业务员"
						optionFilterProp=""
						filterOption={this.select_search}
						loading={this.state.sale_loading}
					>
						{
							this.state.sale_list.map((item,index) => {
								return <Select.Option key={item['id']} value={item['id']}>{item['name_chs']} {item['institution']}</Select.Option>
							})
						}
					</Select>
				</Form.Item>
				{
				// <Form.Item name="sample_type" label="样本类型" >
				// 	<Select
				// 		showSearch
				// 		placeholder=""
				// 		optionFilterProp=""
				// 		filterOption={this.select_search}
				// 	>
				// 		{
				// 			sample_type.map((item,index) => {
				// 				return <Select.Option key={index} value={item}>{item}</Select.Option>
				// 			})
				// 		}
				// 	</Select>
				// </Form.Item>
				}
				<Form.Item name="charge" label="检测单金额" >
					<Input />
				</Form.Item>
			</Form>
		)
	}

	onBirthplaceChange = (e) => {
		console.log(e);
	}
	

	// 选择表型
	openPhenModal = () => {
		this.setState({
			'search_phen': [],
			'phen_search_loading': false,
			'phen_search_modal':true
		});
	}
	closePhenModal = () => {
		this.setState({
			'search_phen': [],
			'phen_search_loading': false,
			'phen_search_modal':false
		});
	}
	searchPhe = (value) => {
		if(value === ''){
			message.error('请输入关键字再搜索');
			return false;
		}
		this.setState({'phen_search_loading':true});
		reqwest({
			url: '/api/hpo',
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {s:value}
		}).then(
			(data) => {
				if(data.status === 1){
					this.setState({
						search_phen: data.results,
						'phen_search_loading':false
					})
				}else{
					message.error(data.msg);
					this.setState({'phen_search_loading':false});
				}
			},
			(err,data) => {
				message.error('请求失败');
				this.setState({'phen_search_loading':false});
			}
		);
	}
	phenSearchPost = () => {
		if(this.state.selected_phen.lenth === 0){
			message.warning('请选择表型');
			return false;
		}
		let new_hpo = [];
		let selectedPhen = this.state.HPO;
		let selected_id = [];
		for(let i in selectedPhen){
			let d = selectedPhen[i];
			selected_id.push(d['id']);
			new_hpo.push('HP:'+d['hpo']);
		}

		for(let i in this.state.search_phen){
			let d = this.state.search_phen[i];
			if(selected_id.indexOf(d['id']) !== -1){
				continue;
			}
			if(this.state.selected_phen.indexOf(d['id']) !== -1){
				selectedPhen.push(d);
				new_hpo.push('HP:'+d['hpo']);
			}
		}
		let data = {};
		data['HPO'] = new_hpo.join(',');
		this.patientSave(data, e => {
			this.setState({
				'phen_search_modal':false,
				'selected_phen': []
			})
		});
	}
	removePhen = (record) => {
		let that = this;
		let new_hpo = [];
		for(let i in this.state.HPO){
			let d = this.state.HPO[i];
			if(d['id'] === record['id']){
				continue;
			}
			new_hpo.push('HP:'+d['hpo']);
		}
		Modal.confirm({
			title: '您确信要删除该表型?',
			icon: <ExclamationCircleOutlined />,
			onOk() {
				that.patientSave({'HPO': new_hpo.join(',')});
			},
		});
	}

	getDoctorSale = (value) => {
		this.setState({'sale_loading':true});
		if(this.refs.edit_test_base !== undefined){
			this.refs.edit_test_base.setFieldsValue({'saleman_id': ''});
		}
		if(!value){
			this.setState({'sale_list':[],'sale_loading':false});
			return true;
		}
		reqwest({
			url: `/api/mgm/doctor/${value}/sales`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					this.setState({
						'sale_list': data.results,
						'sale_loading':false
					})
				}else{
					message.error(data.msg);
				}
			},
			(err,data) => {
				message.error('请求失败');
			}
		);
	}



	openSampleModal = () => {
		this.dataForTestCreate();
		this.setState({'sample_modal': true});
	}
	closeSampleModal = () => {
		this.setState({'sample_modal': false});
	}

	openSelectSampleModal = () => {
		this.dataForTestCreate();
		this.setState({'select_sample_modal': true});
	}
	closeSelectSampleModal = () => {
		this.setState({'select_sample_modal': false});
	}

	postSample = (data, callback) => {
		let that = this;
		reqwest({
			url: `/api/mgm/test/${this.state.test.id}/sample`,
			method: 'POST',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: data
		}).then(
			(data) => {
				if(data.status === 1){
					that.fetch();
					if(callback !== undefined){
						callback();
					}
					message.success('操作成功');

				}else{
					message.error(data.msg);
				}
			},
			(err,data) => {
				message.error('Network error');
			}
		);
	}
	sampleSave = () => {
		let form =this.refs.new_sample;
		form.validateFields().then((data) => {
			this.postSample({'mode': 'select',...data}, () => {
				this.setState({ select_sample_modal: false})
			});
		})
	}

	newSampleSave = () => {
		let that = this;
		let form =this.refs.new_sample;
		form.validateFields().then((data) => {
			if(data['date_collected']){
				data['date_collected'] = moment(data['date_collected']).format('YYYY-MM-DD');
			}
			if(data['birthdate']){
				data['birthdate'] = moment(data['birthdate']).format('YYYY-MM-DD');
			}
			let name = data.sample_name;
			// 检查该检测是否已经添加
			for(let i in this.state.samples){
				let d = this.state.samples[i];
				if(name === d['sample_name']){
					message.error('该样本名称已经存在，请重新填写')
					return false;
				}
			}

			// 检查该样本是否在样本库中存在
			for(let i in this.state.sample_list){
				let d = this.state.sample_list[i];
				if(name === d['sample_name']){
					Modal.confirm({
						title: '该样本在样本库中已经存在，是否要继续创建?',
						icon: <ExclamationCircleOutlined />,
						onOk() {
							that.postSample({'mode':'new',...data}, () => {
								that.setState({ sample_modal: false})
							});
						},
					});
					return false;
				}
			}
			if(data.other_sample_type){
				data['sample_type'] = data.other_sample_type;
			}

			that.postSample({'mode':'new',...data}, () => {
				that.setState({ sample_modal: false})
			});
		})
	}

	removeSample = (record) => {
		let that = this;
		Modal.confirm({
			title: '您确信要删除该样本?',
			icon: <ExclamationCircleOutlined />,
			onOk() {
				reqwest({
					url: `/api/mgm/test/${that.state.test.id}/sample`,
					method: 'DELETE',
					headers: {
						'Authorization': that.state.token,
					},
					type: 'json',
					data: {id: record['id']}
				}).then(
					(data) => {
						if(data.status === 1){
							that.fetch();
							message.success('删除成功');
						}else{
							message.error(data.msg);
						}
					},
					(err,data) => {
						message.error('Network error');
					}
				);
			},
		});
	}

	rsyncPatient = () =>{
		let that = this;
		Modal.confirm({
			title: '警告',
			'content': '同步操作会将病例库中的信息覆盖当前已经填写的病例信息，您确定要这样操作？',
			icon: <ExclamationCircleOutlined />,
			onOk() {
				reqwest({
					url: `/api/mgm/test/${that.state.test.id}/rsync`,
					method: 'POST',
					headers: {
						'Authorization': that.state.token,
					},
					type: 'json',
				}).then(
					(data) => {
						if(data.status === 1){
							that.fetch();
							message.success('同步成功');
						}else{
							message.error(data.msg);
						}
					},
					(err,data) => {
						message.error('Network error');
					}
				);
			},
		});
	}

	openEditSample = (record) => {
		// 日期格式转化
		let data = {...record}
		data['birthdate'] = moment(data['birthdate'],"YYYY-MM-DD");
		data['date_collected'] = moment(data['date_collected'],"YYYY-MM-DD");
		this.setState({'sample_edit_modal' : true, 'sample_edit_data': data})
	}

	closeEditSample = (record) => {
		this.setState({'sample_edit_modal' : false, 'sample_edit_data': {}})
	}

	saveEditSample = () => {
		let form = this.refs.edit_sample;
		let id = this.state.sample_edit_data['sample_id'];
		let that = this;
		form.validateFields().then((data) => {
			// 日期转化成字符串
			data['birthdate'] = moment(data['birthdate']).format("YYYY-MM-DD");
			data['date_collected'] = moment(data['date_collected']).format("YYYY-MM-DD");

			reqwest({
				url: `/api/mgm/test/${that.state.test.id}/sample`,
				method: 'PATCH',
				headers: {
					'Authorization': that.state.token,
				},
				type: 'json',
				data: {id:id, ...data}
			}).then(
				(data) => {
					if(data.status === 1){
						message.success('修改成功');
						that.fetch()
						that.closeEditSample()
					}else{
						message.error(data.msg);
					}
				},
				(err,data) => {
					message.error('请求失败');
				}
			);
		})
	}
	
	
	// 删除已经上传的图片
	removeMediaAndRemote = (id) => {
		let that = this;
		Modal.confirm({
			title: '注意',
			icon: <ExclamationCircleOutlined />,
			content: '您确定删除该记录?',
			onOk() {
				reqwest({
					url: `/api/mgm/test/${that.state.test.id}/media`,
					method: 'DELETE',
					headers: {
						'Authorization': that.state.token,
					},
					type: 'json',
					data: {id:id}
				}).then(
					(data) => {
						if(data.status === 1){
							message.success('删除成功');
							that.fetch()
						}else{
							message.error(data.msg);
						}
					},
					(err,data) => {
						message.error('请求失败');
					}
				);
    		},
 		});
	}
	
	// 图片预览样式
	image_card = (item, index) => {
		return (
			<Col  span={4} key={item['id']} style={{marginBottom:'9px'}}>
				<div className="test-card">
					<div >
						{
							item['file_type'] === 'image' ? 
								<Image className="card-image" src={item['file_path']} alt=""  />
							:
								<video onClick={() => {this.viewMedia(index)}} className="card-image" src={item['file_path']} />
						}
					</div>
					<div onClick={() => {this.removeMediaAndRemote(item['id'])}} className="card-close"><CloseCircleOutlined twoToneColor="#FF3B42" /></div>
				</div>
			</Col>
		)

	}

	viewMedia = (index) => {
		let media = this.state.media[index];
		this.setState({
			'view_media': media,
			'view_modal': true
		})
	}
	closeViewMedia = (index) => {
		this.setState({
			'view_media': null,
			'view_modal': false
		})
	}

	viewHPO = (record) => {
  		console.log(record);
  		this.setState({
  			'hpo_view': record,
  			'hpo_view_modal': true,
  		})
  	}
  	closeViewHPO = (record) => {
  		this.setState({
  			'hpo_view': {},
  			'hpo_view_modal': false,
  		})
  	}

  	updateFile = (file_id) => {
  		let this_edit_file = {};
  		for(let i in this.state.files){
  			if(this.state.files[i].id === file_id){
  				this_edit_file = {...this.state.files[i]};
  				break;
  			}
  		}
  		this_edit_file['new_pat'] = this_edit_file['new_pat'] === '1' ? true : false;
  		this_edit_file['new_gene'] = this_edit_file['new_gene'] === '1' ? true : false;
  		this.setState({
  			'edit_report_modal': true, 
  			'this_edit_file_id': file_id,
  			'this_edit_file': this_edit_file,
  		});
  	}
  	closeEditReportModal = () => {
  		this.setState({'edit_report_modal': false, 'this_edit_file_id': '','this_edit_file':{} });
  	}

  	reportEdit = () => {
  		let that = this;
  		let form = this.refs.edit_report;
		form.validateFields().then((data) => {
			let conclusion = data.conclusion;
			
			if(!conclusion){
				message.error('请填写结论');
				return false;
			}
			reqwest({
				url: `/api/mgm/test/report`,
				method: 'PATCH',
				headers: {
					'Authorization': that.state.token,
				},
				type: 'json',
				data: {
					test_id:this.state.test.id, 
					file_id: this.state.this_edit_file_id, 
					'conclusion': conclusion, 
					'comment': data.comment,
				}
			}).then(
				(data) => {
					if(data.status === 1){
						message.success('修改成功');
  						this.setState({'edit_report_modal': false, 'this_edit_file_id': ''});
						that.fetch()
					}else{
						message.error(data.msg);
					}
				},
				(err,data) => {
					message.error('请求失败');
				}
			);

		})
  	}


  	// 科学发现
  	openDiscoveryModal = () => {
  		this.setState({
  			'discover_modal' : true,
  		})
  	}
  	closeDiscoverModal = () => {
  		this.setState({
  			'discover_modal' : false,
  		})
  	}
  	discoverSave = () => {
  		let that = this;
  		let form = this.refs.discover;
		form.validateFields().then((data) => {
			console.log(data);
			data['new_pat_found'] = data['new_pat_found'] ? 1 : 0; 
			data['new_gene_found'] = data['new_gene_found'] ? 1 : 0; 
			data['need_function_study'] = data['need_function_study'] ? 1 : 0; 
			that.testSave(data, ()=>{
				that.setState({'discover_modal': false})
			});
		})
  	}
  	

	render() {
		if(! this.state.token){
			return <Redirect to="/login"/>
		}
		if(this.state.redirect){
			return <Redirect to={this.state.redirect}/>
		}
		let that = this;
		const props = {
			beforeUpload: (file) => {
				this.setState({
					tempFile: file
				});
				return false;
			},
			multiple: false,
			accept: ".pdf,.doc,.docx,.gz,.zip,.rar",
			fileList: this.state.tempFile ? [this.state.tempFile] : [],
			onRemove: function(){
				that.setState({'tempFile': undefined});
			}
		}

		const props2 = {
			action:`/api/mgm/test/${this.state.test.id}/media`,
			name: 'data',
			multiple: false,
			headers: {'Authorization': this.state.token},
			accept: ".png,.jpg,.jpeg,.gff,.mp4,.mov",
			data: (file) => {
				let type = file.type;
				let re = /^image/;
				if(re.test(type)){
					type = 'image';
				}else{
					type = 'video';
				}
				return {'type': type}
			},
			showUploadList: false,
			onChange: (data) => {
				if(data.file.status === 'done'){
					this.fetch();
				}
			},
		}
		return (
			<div>
				<Header {...this.state} L1="tests" />
				<div className="max-width">
        			<Breadcrumb style={{margin:'10px 0'}}>
        				<Breadcrumb.Item><Link to="/tests">检测列表</Link></Breadcrumb.Item>
        				<Breadcrumb.Item>{this.state.test.test_sn}</Breadcrumb.Item>
        			</Breadcrumb>
        			{//<div>
        			//	<Button key="2" type="primary" onClick={this.rsyncPatient}>导入病例信息</Button>
        			//</div>
        			}

        			<PageHeader title="检测信息" style={{paddingLeft:0,paddingRight:0}}
	        			extra={[
							<Button key="1" type="primary" onClick={e => {this.openEditModal('edit_test_base')}}>编辑</Button>,
						]}
	        		>
	        			<Descriptions size="small" column={4}  bordered className="table_list">
	        				<Descriptions.Item label="检测编号">{this.state.test.test_sn} </Descriptions.Item>
	        				<Descriptions.Item label="检测项目">{this.state.test.product_name} </Descriptions.Item>
	        				<Descriptions.Item label="检测金额" >{this.state.test.charge}￥ </Descriptions.Item>
	        				{
	        				//<Descriptions.Item label="样本类型" >{this.state.test.sample_type}</Descriptions.Item>
	        				}
	        				<Descriptions.Item label="状态" >{this.state.test.status} </Descriptions.Item>

	        				<Descriptions.Item label="医生姓名">{this.state.doctor ? this.state.doctor.name_chs : ''} </Descriptions.Item>
	        				<Descriptions.Item label="医生邮箱">{this.state.doctor ? this.state.doctor.email : ''} </Descriptions.Item>
	        				<Descriptions.Item label="医生单位">{this.state.doctor ? this.state.doctor.institution : ''} </Descriptions.Item>
	        				<Descriptions.Item label="医生电话">{this.state.doctor ? this.state.doctor.mobile : ''} </Descriptions.Item>

	        				<Descriptions.Item label="业务员姓名">{this.state.sale ? this.state.sale.name_chs : ''} </Descriptions.Item>
	        				<Descriptions.Item label="业务员邮箱">{this.state.sale ? this.state.sale.email : ''} </Descriptions.Item>
	        				<Descriptions.Item label="业务员电话">{this.state.sale ? this.state.sale.mobile : ''} </Descriptions.Item>

	        				<Descriptions.Item label="创建时间">{this.state.sale ? this.state.test.created_at : ''} </Descriptions.Item>
	        			</Descriptions>
	        		</PageHeader>

        			<PageHeader title="受检者基本信息" style={{paddingLeft:0,paddingRight:0}} 
        				extra={[
							<Button key="1" type="primary" onClick={e => {this.openEditModal('edit_base')}}>编辑</Button>
						]}
        			>
	        			<Descriptions size="small" column={4}  bordered className="table_list">
	        				<Descriptions.Item label="姓名">{this.state.patient['name']} </Descriptions.Item>
	        				<Descriptions.Item label="身份证">{this.state.patient['ID_number']} </Descriptions.Item>
	        				<Descriptions.Item label="性别">{this.state.patient['gender']} </Descriptions.Item>
	        				<Descriptions.Item label="出生日期">{moment(this.state.patient['birthdate']).format('YYYY-MM-DD')}</Descriptions.Item>

	        				<Descriptions.Item label="籍贯">{this.state.patient['birthplace']} </Descriptions.Item>
	        				<Descriptions.Item label="年龄">{autoCalculateAge(this.state.patient['birthdate'])} </Descriptions.Item>
	        				<Descriptions.Item label="民族">{this.state.patient.race} </Descriptions.Item>
	        				<Descriptions.Item label="国家">{this.state.patient.country} </Descriptions.Item>

	        				<Descriptions.Item label="联系人">{this.state.patient['contact_name']} </Descriptions.Item>
	        				<Descriptions.Item label="固定电话">{this.state.patient['tel']} </Descriptions.Item>
	        				<Descriptions.Item label="手机号码">{this.state.patient['mobile']} </Descriptions.Item>
	        				<Descriptions.Item label="地址">{this.state.patient['address']} </Descriptions.Item>
	        			</Descriptions>
	        		</PageHeader>

	        		<PageHeader title="临床症状和起病年龄" style={{paddingLeft:0,paddingRight:0}}
        				extra={[
							<Button key="1" type="primary" onClick={e => {this.openEditModal('edit_clinical')}}>编辑</Button>,
						]}
        			>
	        			<Descriptions size="small" column={1}  bordered className="table_list">
	        				<Descriptions.Item label="病历摘要" >{this.state.patient.clinical_abstract} </Descriptions.Item>
	        				<Descriptions.Item label="主要症状" >{this.state.patient.clinical_phenotypes} </Descriptions.Item>
	        				<Descriptions.Item label="起病年龄">{ageView(this.state.patient.age_onset)} </Descriptions.Item>
	        				<Descriptions.Item label="临床诊断">{this.state.patient.diagnosis} </Descriptions.Item>
	        			</Descriptions>
	        		</PageHeader>
	        		<PageHeader title="HPO表型" style={{paddingLeft:0,paddingRight:0}}
	        			extra={[
							<Button key="1" type="primary" onClick={this.openPhenModal}>添加</Button>,
						]}
	        		>
	        			<Table
							style={{marginTop:0}}
							size="small" 
							rowKey="id"
							bordered 
							columns={this.state.phen_on_show} 
							dataSource={this.state.HPO}
							pagination={false}
							locale={{emptyText:'No Data',}}
						/>
	        		</PageHeader>

					<PageHeader title="家族史" style={{paddingLeft:0,paddingRight:0}}
						extra={[
							<Button key="1" type="primary" onClick={e => {this.openEditModal('edit_history')}}>编辑</Button>,
						]}
					>
	        			<Descriptions size="small" column={1}  bordered className="table_list2">
	        				<Descriptions.Item 
	        					label={
									<span>
										有无家族史&nbsp;
										<Tooltip title="三代以内的亲属有无患类似疾病的个体">
											<QuestionCircleOutlined />
										</Tooltip>
									</span>
								} >{this.state.patient.family_history}</Descriptions.Item>
	        				<Descriptions.Item label="父母是否近亲结婚	" >{this.state.patient.is_consanguineous}</Descriptions.Item>
	        				<Descriptions.Item label="家族史详情" >{this.state.patient.family_history_text}</Descriptions.Item>
	        			</Descriptions>
	        		</PageHeader>

        			<PageHeader title="样本" style={{paddingLeft:0,paddingRight:0}}
        				tags={<Alert message="注意：病人本身的样本信息也需要添加" type="warning" style={{'padding':'4px 15px'}} />}
        				extra={[
							<Button key="1" type="primary" onClick={this.openSampleModal}>添加样本</Button>,
							<Button key="2" type="primary" onClick={this.openSelectSampleModal}>选择样本</Button>,
						]}
        			>
        				<Table 
							bordered 
							rowKey="id" 
							size="small" 
							columns={this.sample_column} 
							dataSource={this.state.samples}
							pagination={false}
							locale={{emptyText:'No Data',}}
						/>
	        		</PageHeader>

        			<PageHeader title="时间节点" style={{paddingLeft:0,paddingRight:0}}
        			>
        				<Form
							style={{marginTop:'20px'}}
							initialValues={{}}
							onFinish={this.updateDate}
							ref="test_date"
							layout="horizontal"
							labelCol = {{ span: 10 }}
     						wrapperCol = {{ span: 14 }}
     						labelAlign="left"
						>
							<Row>
								<Col span={5}>
									<Form.Item name="date_sample_collected" label={
										<div>送样日期 <Tooltip title="填写报告单上的日期"> <QuestionCircleOutlined /></Tooltip></div>
									}>
										<DatePicker locale={zhCN.DatePicker}  />
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item name="date_sample_received" label={
										<div>样本接收日期 <Tooltip title="实验室接收样本的时间"> <QuestionCircleOutlined /></Tooltip></div>
									}>
										<DatePicker locale={zhCN.DatePicker}  />
									</Form.Item>
								</Col>
								
								<Col span={7}>
									<Form.Item name="date_expr_done" label="测序/实验完成的日期">
										<DatePicker locale={zhCN.DatePicker}  />
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item name="date_reported" label="报告发放日期">
										<DatePicker locale={zhCN.DatePicker}  />
									</Form.Item>
								</Col>
							</Row>

							<Form.Item  wrapperCol= {{ offset: 0, span: 24 }}>
								<Button type="primary" htmlType="submit">保存</Button>
							</Form.Item>
						</Form>
	        		</PageHeader>
        			<PageHeader title="检测报告" tags={<Alert message="请确认报告无误后再上传，报告上传后会马上通知医生和业务员" type="warning" style={{'padding':'4px 15px'}} />}
        				style={{paddingLeft:0,paddingRight:0}}
        				extra={[ <Button type="primary" onClick={this.openUploadModal} key="1" >新增检测报告</Button>]}
        			>
        				<Table 
							bordered 
							rowKey="id" 
							size="small" 
							columns={this.file_column} 
							dataSource={this.state.files}
							pagination={false}
							locale={{emptyText:'No Data',}}
						/>
	        		</PageHeader>

	        		<PageHeader title="科学发现" style={{paddingLeft:0,paddingRight:0}}
        				extra={[ <Button type="primary" onClick={this.openDiscoveryModal} key="1" >编辑科学发现</Button>]}
        			>
        				<Descriptions size="small" column={1}  bordered className="table_list4">
        					<Descriptions.Item label="发现或可能发现新的致病变异" >{this.state.test.new_pat_found === '1' ? '是' : '否' }</Descriptions.Item>
        					<Descriptions.Item label="发现新的致病基因" >{this.state.test.new_gene_found === '1' ? '是' : '否'}</Descriptions.Item>
        					<Descriptions.Item label="有必要开展功能研究" >{this.state.test.need_function_study === '1' ? '是' : '否'}</Descriptions.Item>
        					<Descriptions.Item label="备注" >{this.state.test.comment}</Descriptions.Item>
        				</Descriptions>
	        		</PageHeader>

	        		<PageHeader title="申请单" style={{paddingLeft:0,paddingRight:0}}
	        			extra={[
							<Upload key="1"  {...props2} ><Button type="primary"><PlusOutlined /> 添加申请单</Button></Upload>
						]}
	        		>
						<Row gutter={16} >
						{
							this.state.media.map((item,index) => {
								return this.image_card(item,index);
							})
						}
						</Row>
						{this.state.media.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> : ''}
	        		</PageHeader>
				</div>
				<Modal
		        	title={<BuildTitle visible={this.state.upload_modal} id="upload_modal" title="新增检测报告" />}
					visible={this.state.upload_modal}
					onCancel={this.closeUploadModal}
					onOk={this.reportSave}
					width="600px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('upload_modal').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Alert message="请确认报告无误后再上传，报告上传后会马上通知医生和业务员" type="warning" showIcon closable style={{'marginBottom':'16px'}} />
					<Form
						style={{marginTop:'0px'}}
						ref="new_report"
						layout="horizontal"
						initialValues={{}}
						labelCol = {{ span: 4 }}
						wrapperCol = {{ span: 20 }}
						labelAlign="left"
					>
						
						<Row className="cell">
							<Col span="4" className="cell-title">临床报告</Col>
							<Col span="20" className="cell-body">
								<Upload {...props} >
									<Button><UploadOutlined /> 选择文件</Button>
								</Upload>
							</Col>
						</Row>

						<Form.Item name="conclusion" label="结论" rules={[{ required: true }]} >
							<Radio.Group>
								<p><Radio value="1">阳性，检测到能完全解释患者临床表现的致病或疑似致病变异</Radio></p>
								<p><Radio value="2">检测到和患者表型相关的变异，但不能完全解释患者的临床表现</Radio></p>
								<p><Radio value="3">未检测和患者表型相关的变异</Radio></p>
								<p><Radio value="9">其他</Radio></p>
							</Radio.Group>
						</Form.Item>

						<Form.Item name="comment" label="备注" >
							<Input.TextArea />
						</Form.Item>
						
					</Form>
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.edit_report_modal} id="edit_report_modal" title="编辑结论" />}
					visible={this.state.edit_report_modal}
					onCancel={this.closeEditReportModal}
					onOk={this.reportEdit}
					maskClosable={false}
					destroyOnClose={true}
					width="600px"
					afterClose={() => {
			            try {
							document.getElementById('edit_report_modal').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="edit_report"
						layout="horizontal"
						initialValues={this.state.this_edit_file}
						labelCol = {{ span: 4 }}
						wrapperCol = {{ span: 20 }}
						labelAlign="left"
					>
						<Form.Item name="conclusion" label="结论" rules={[{ required: true }]}  >
							<Radio.Group>
								<p><Radio value="1">阳性，检测到能完全解释患者临床表现的致病或疑似致病变异</Radio></p>
								<p><Radio value="2">检测到和患者表型相关的变异，但不能完全解释患者的临床表现</Radio></p>
								<p><Radio value="3">未检测和患者表型相关的变异</Radio></p>
								<p><Radio value="9">其他</Radio></p>
							</Radio.Group>
						</Form.Item>

						<Form.Item name="comment" label="备注" >
							<Input.TextArea />
						</Form.Item>

					</Form>
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.edit_base} id="edit_base" title="编辑基本信息" />}
					visible={this.state.edit_base}
					onCancel={e =>{this.closeEditModal('edit_base')}}
					onOk={e => {this.editSave('edit_base')} }
					width="800px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('edit_base').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					{this.edit_base(this.state.patient)}
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.edit_clinical} id="edit_clinical" title="编辑临床诊断信息" />}
					visible={this.state.edit_clinical}
					onCancel={e =>{this.closeEditModal('edit_clinical')}}
					onOk={e => {this.editSave('edit_clinical')} }
					width="800px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('edit_clinical').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					{this.edit_clinical(this.state.patient)}
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.edit_history} id="edit_history" title="编辑家族史信息" />}
					visible={this.state.edit_history}
					onCancel={e =>{this.closeEditModal('edit_history')}}
					onOk={e => {this.editSave('edit_history')} }
					width="800px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('edit_history').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					{this.edit_history(this.state.patient)}
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.phen_search_modal} id="phen_search_modal" title="选择表型" />}
					visible={this.state.phen_search_modal}
					width="900px"
					onCancel={this.closePhenModal}
					onOk={this.phenSearchPost}
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('phen_search_modal').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>	
					<Input.Search
						placeholder="请输入表型的中文、英文或者HPO号后按回车搜索"
						enterButton
						onSearch={value => this.searchPhe(value)}
					/>
					<Table
						style={{marginTop:10}}
						size="small" 
						rowKey="id"
						loading={this.state.phen_search_loading} 
						bordered 
						columns={phen_table_column} 
						dataSource={this.state.search_phen}
						rowSelection={{
							type: 'checkbox',
							onChange: (selectedRowKeys, selectedRows) => {
								this.setState({selected_phen:selectedRowKeys})
							}
						}}
						pagination={{  //分页
					        total: this.state.search_phen.length, //数据总数量
					        showSizeChanger: true,  //是否显示可以设置几条一页的选项
					        showTotal:  () => {return '搜索到' + this.state.search_phen.length + '条数据'; }
					    }}
					    locale={{emptyText:'No Data',}}
					/>
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.edit_test_base} id="edit_test_base" title="编辑检测基本信息" />}
					visible={this.state.edit_test_base}
					onCancel={e =>{this.closeEditModal('edit_test_base')}}
					onOk={e => {this.editSave('edit_test_base')} }
					width="800px"
					destroyOnClose={true}
			        maskClosable={false}
			        afterClose={() => {
			            try {
							document.getElementById('edit_test_base').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					{this.edit_test_base(this.state.test)}
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.sample_modal} id="sample_modal" title="添加一个新样本" />}
					visible={this.state.sample_modal}
					onCancel={this.closeSampleModal}
					onOk={this.newSampleSave}
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
			            	document.getElementById('sample_modal').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="new_sample"
						layout="horizontal"
						initialValues={{is_new:'是'}}
						labelCol = {{ span: 5 }}
						wrapperCol = {{ span: 19 }}
						labelAlign="left"
					>
						<Form.Item name="sample_name" label="姓名" rules={[{ required: true }]} >
							<Input value="" />
						</Form.Item>
						<Form.Item label="样本类型" >
							<Form.Item name="sample_type" style={{ display: 'inline-block', width: 'calc(50% - 12px)' ,marginBottom:0}} >
								<Select
									showSearch
									placeholder=""
									optionFilterProp=""
									filterOption={this.select_search}
									onChange={(v) => {
										if(v === '其他'){
											this.setState({'other_sample_type': true})
										}else{
											this.setState({'other_sample_type': false})
										}
									}}
								>
									{
										sample_type.map((item,index) => {
											return <Select.Option key={index} value={item}>{item}</Select.Option>
										})
									}
								</Select>
							</Form.Item>
							{
								this.state.other_sample_type ? (
									<Form.Item name="other_sample_type" style={{ display: 'inline-block', width: 'calc(50% - 12px)', marginLeft:'24px' ,marginBottom:0}} >
										<Input placeholder="请填写其他类型" />
									</Form.Item>
								) : ''
							}
							

						</Form.Item>
						<Form.Item name="birthdate" label="出生日期"  >
							<DatePicker locale={zhCN.DatePicker}  />
						</Form.Item>
						<Form.Item name="date_collected" label="采样日期" >
							<DatePicker locale={zhCN.DatePicker}  />
						</Form.Item>
						<Form.Item name="relationship" label="亲缘关系" rules={[{ required: true }]} >
							<Select>
								{
									Object.keys(relationship).map((item,index) => {
										return <Select.Option key={item} value={item}>{relationship[item]}</Select.Option>
									})
								}
							</Select>
						</Form.Item>
						<Form.Item name="is_affected" label="是否患病" rules={[{ required: true }]} >
							<Radio.Group>
								<Radio value="是">是</Radio>
								<Radio value="否">否</Radio>
							</Radio.Group>
						</Form.Item>
						<Form.Item name="do_test" label="是否检测" rules={[{ required: true }]} >
							<Radio.Group>
								<Radio value="是">是</Radio>
								<Radio value="否">否</Radio>
							</Radio.Group>
						</Form.Item>
					</Form>
				</Modal>


				<Modal
		        	title={<BuildTitle visible={this.state.sample_edit_modal} id="sample_edit_modal" title="编辑样本" />}
					visible={this.state.sample_edit_modal}
					onCancel={this.closeEditSample}
					onOk={this.saveEditSample}
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
			            	document.getElementById('sample_modal').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="edit_sample"
						layout="horizontal"
						initialValues={this.state.sample_edit_data}
						labelCol = {{ span: 5 }}
						wrapperCol = {{ span: 19 }}
						labelAlign="left"
					>
						<Form.Item name="sample_name" label="姓名" rules={[{ required: true }]} >
							<Input value="" />
						</Form.Item>
						<Form.Item label="样本类型" >
							<Form.Item name="sample_type" style={{ display: 'inline-block', width: 'calc(50% - 12px)' ,marginBottom:0}} >
								<Select
									showSearch
									placeholder=""
									optionFilterProp=""
									filterOption={this.select_search}
									onChange={(v) => {
										if(v === '其他'){
											this.setState({'other_sample_type': true})
										}else{
											this.setState({'other_sample_type': false})
										}
									}}
								>
									{
										sample_type.map((item,index) => {
											return <Select.Option key={index} value={item}>{item}</Select.Option>
										})
									}
								</Select>
							</Form.Item>
							{
								this.state.other_sample_type ? (
									<Form.Item name="other_sample_type" style={{ display: 'inline-block', width: 'calc(50% - 12px)', marginLeft:'24px' ,marginBottom:0}} >
										<Input placeholder="请填写其他类型" />
									</Form.Item>
								) : ''
							}
							

						</Form.Item>
						<Form.Item name="birthdate" label="出生日期"  >
							<DatePicker locale={zhCN.DatePicker}  />
						</Form.Item>
						<Form.Item name="date_collected" label="采样日期" >
							<DatePicker locale={zhCN.DatePicker}  />
						</Form.Item>
						<Form.Item name="relationship" label="亲缘关系" rules={[{ required: true }]} >
							<Select>
								{
									Object.keys(relationship).map((item,index) => {
										return <Select.Option key={item} value={item}>{relationship[item]}</Select.Option>
									})
								}
							</Select>
						</Form.Item>
						<Form.Item name="is_affected" label="是否患病" rules={[{ required: true }]} >
							<Radio.Group>
								<Radio value="是">是</Radio>
								<Radio value="否">否</Radio>
							</Radio.Group>
						</Form.Item>
						<Form.Item name="do_test" label="是否检测" rules={[{ required: true }]} >
							<Radio.Group>
								<Radio value="是">是</Radio>
								<Radio value="否">否</Radio>
							</Radio.Group>
						</Form.Item>
					</Form>
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.select_sample_modal} id="select_sample_modal" title="选择已经添加的样本" />}
					visible={this.state.select_sample_modal}
					onCancel={this.closeSelectSampleModal}
					onOk={this.sampleSave}
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
			            	document.getElementById('select_sample_modal').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="new_sample"
						layout="horizontal"
						initialValues={{is_new:'是'}}
						labelCol = {{ span: 5 }}
						wrapperCol = {{ span: 19 }}
						labelAlign="left"
					>
						<Form.Item name="sample_id" label="姓名" rules={[{ required: true }]} >
							<Select
								showSearch
								placeholder="请选择一个样本"
								optionFilterProp=""
								filterOption={this.select_search}
							>
								{
									this.state.sample_list.map((item,index) => {
										return <Select.Option value={item.id} key={index}> {item.sample_name} &nbsp;&nbsp;{item.sample_type} &nbsp;&nbsp;{item.date_collected} </Select.Option>
									})
								
								}
							</Select>
						</Form.Item>
						<Form.Item name="relationship" label="亲缘关系" rules={[{ required: true }]} >
							<Select>
								{
									Object.keys(relationship).map((item,index) => {
										return <Select.Option key={item} value={item}>{relationship[item]}</Select.Option>
									})
								}
							</Select>
						</Form.Item>
						<Form.Item name="do_test" label="是否检测" rules={[{ required: true }]} >
							<Radio.Group>
								<Radio value="是">是</Radio>
								<Radio value="否">否</Radio>
							</Radio.Group>
						</Form.Item>

					</Form>
				</Modal>


				<Modal
		        	title={<BuildTitle visible={this.state.discover_modal} id="discover_modal" title="编辑科学发现" />}
					visible={this.state.discover_modal}
					onCancel={this.closeDiscoverModal}
					onOk={this.discoverSave}
					width="600px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
			            	document.getElementById('discover_modal').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="discover"
						layout="horizontal"
						initialValues={{
							new_pat_found:this.state.test.new_pat_found === '1' ? true : false,
							new_gene_found:this.state.test.new_gene_found === '1' ? true : false,
							need_function_study:this.state.test.need_function_study === '1' ? true : false,
							comment:this.state.test.comment
						}}
						labelCol = {{ span: 24 }}
						wrapperCol = {{ span: 24 }}
						labelAlign="left"
					>
						<Form.Item name="new_pat_found" valuePropName="checked" >
							<Checkbox> 发现或可能发现新的致病变异</Checkbox>
						</Form.Item>
						<Form.Item name="new_gene_found" valuePropName="checked" >
							<Checkbox > 发现新的致病基因</Checkbox>
						</Form.Item>

						<Form.Item name="need_function_study" valuePropName="checked">
							<Checkbox > 有必要开展功能研究</Checkbox>
						</Form.Item>

						<Form.Item name="comment" label="备注" >
							<Input.TextArea/>
						</Form.Item>

					</Form>
				</Modal>

				<Modal
		        	title='图片预览'
					visible={this.state.view_modal}
					onCancel={this.closeViewMedia}
					footer={null}
					width="960px"
					maskClosable={false}
					destroyOnClose={true}
				>
					<div className="card-image" >
						{
							this.state.view_media ?
								(this.state.view_media['file_type'] === 'image' ?
								<img style={{width:'100%'}} src={this.state.view_media && this.state.view_media['file_path']} alt="" />
								:
								<video style={{width:'100%'}}  className="card-image" src={this.state.view_media && this.state.view_media['file_path']} controls="controls" />)
							: ''
						}
					</div>
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.hpo_view_modal} id="hpo_view_modal" title={this.state.hpo_view ? 'HP:'+this.state.hpo_view['hpo'] : ''} />}
					visible={this.state.hpo_view_modal}
					onCancel={this.closeViewHPO}
					footer={null}
					width="720px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
			            	document.getElementById('hpo_view_modal').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Descriptions size="small" column={1}  bordered className="table_list">
        				<Descriptions.Item label="HPO id" >{this.state.hpo_view.hpo} </Descriptions.Item>
        				<Descriptions.Item label="中文名称" >{this.state.hpo_view.name_chs} </Descriptions.Item>
        				<Descriptions.Item label="英文名称" >{this.state.hpo_view.name} </Descriptions.Item>
        				<Descriptions.Item label="中文定义" >{this.state.hpo_view.definition_chs} </Descriptions.Item>
        				<Descriptions.Item label="英文定义" >{this.state.hpo_view.definition} </Descriptions.Item>
        			</Descriptions>
				</Modal>
				<Footer />

				
			</div>
		)
	}
}