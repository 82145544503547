import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import {Row, Col ,Menu,Dropdown} from 'antd';
import { DownOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css'
import './css/header.css'
// import logo from './images/logo.png'



export class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};
	}

	// componentWillReceiveProps(nextProps:any) {
	// 	this.setState({
	// 		user_name : nextProps.user_name,
	// 	})
	// }
	UNSAFE_componentWillReceiveProps(nextProps:any) {
		this.setState({
			user_name : nextProps.user_name,
			role : nextProps.role,
		})
	} 
	
	tags = () => {
		return (
			<span>
			{this.state.role === 'admin' ? (<span>
			<Link to="/cases" className={this.state.L1 === 'cases' ? 'active' : ''}><span className="header-list hand" >病例库</span></Link>
			<Link to="/tests" className={this.state.L1 === 'tests' ? 'active' : ''}><span className="header-list hand" >检测列表</span></Link>
			<Link to="/samples" className={this.state.L1 === 'samples' ? 'active' : ''}><span className="header-list hand" >样本列表</span></Link>
			<Link to="/products" className={this.state.L1 === 'products' ? 'active' : ''}><span className="header-list hand" >检测项目</span></Link>
			<Link to="/doctors" className={this.state.L1 === 'doctors' ? 'active' : ''}><span className="header-list hand" >医生管理</span></Link>
			<Link to="/sales" className={this.state.L1 === 'sales' ? 'active' : ''}><span className="header-list hand" >业务员管理</span></Link>
			<Link to="/apply" className={this.state.L1 === 'apply' ? 'active' : ''}><span className="header-list hand" >申请管理</span></Link>
			<Link to="/crog/genes" className={this.state.L1 === 'crog' ? 'active' : ''}><span className="header-list hand" >CROG</span></Link>
			</span>) : ''}
			{this.state.role === 'supadmin' ?<Link to="/users" className={this.state.L1 === 'users' ? 'active' : ''}><span className="header-list hand" >用户管理</span></Link> : ''}
			</span>
		)
  		
	}

	render() {
	
		const menu1 = (
			<Menu>
				<Menu.Item>
					<Link to="/setting">用户设置</Link>
				</Menu.Item>
				<Menu.Item>
					<Link to="/logout">退出登录</Link>
				</Menu.Item>
			</Menu>
		);

	  	if (this.state.token) {
		    return (
		    	<div className="header">
		    		<Row className="max-width">
				    	<Col span={4}>
				    		<Link to="/" className="logo">
				      			MGM
				      		</Link>
				    	</Col>
				    	
				      	<Col span={20} className="text-right">
				      		{this.tags()}
				      		<Dropdown className="hand" overlay={menu1} trigger={['click']} >
			      				<span className="header-list" onClick={e => e.preventDefault()} >
			      					{this.state.user_name} <DownOutlined />
			      				</span>
							</Dropdown>
				      	</Col>
				    </Row>
		    	</div>
		    );
		}else{
			return (
		    	<div className="header">
		    		<Row className="max-width">
				    	<Col span={12}>
				      		<Link to="/" className="logo">
				      			MGM
				      		</Link>
				    	</Col>
				    	
				      	<Col span={12} className="text-right">
				      		<Link to="/login"><span className="header-list">Log in</span></Link>
				      		<Link to="/apply"><span className="header-list header-border">Sign out</span></Link>
				      	</Col>
				    </Row>
		    	</div>
		    );
		}
	}
}