import React, {Component} from 'react';
import {Row,Col} from 'antd';
import {Form,Input,Button,message,Result} from 'antd';
import {MailOutlined} from '@ant-design/icons';
import reqwest from 'reqwest';
import 'whatwg-fetch'
import 'antd/dist/antd.css'
import '../css/login.css'

import { Redirect,Link } from 'react-router-dom'

export class Sendmail extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	'send_status': false,
	    	'loading' : false,
	    }
	}

	handlePost = (data) => {
		this.setState({loading: true});
		reqwest({
			url: '/api/password/sendmail',
			method: 'POST',
			type: 'json',
			data: {email:data.email},
		}).then(
			(data) => {
				if(data.status === 1){
					this.setState({send_status: true,'loading': false})
				}else{
					message.error(data.msg);
				}
			},
			(err,data) => {
				message.error('请求失败');
			}
		);
	}

	backTo = () => {
		this.setState({'redirect' : '/'})
	}
	sendAgain = () =>{
		this.setState({send_status: false});
	}

	render() {
		if(this.state.redirect){
			return <Redirect to={this.state.redirect}/>
		}
		if(this.state.send_status){
			return (
				<Result
					style={{marginTop:'10%'}}
					status="success"
					title="We had sended a password reset link  to your email!"
					subTitle="Please go to your email address to activate your account."
					extra={[
						<Button type="primary" key="back" onClick={this.backTo}> Back to Pubvar</Button>,
						<Button key="back" onClick={this.sendAgain}>Send again</Button>,
					]}
				/>
			)
		}
		return (
			<div>
        		<div className="login-body">
        			<div className="max-width">

						<Row justify="center" align="middle" style={{marginTop:'12%'}}>
			    			<Col span={8}>
				    			<div style={{textAlign:'center', marginBottom:40,fontSize:'35px','color':'#1890ff'}}>
				    				MGM
								</div>
		    					<Form
							      name="normal_login"
							      className="login-form"
							      initialValues={{ remember: true }}
							      onFinish={this.handlePost}
							    >
									<p style={{color:'#999'}}>Enter your email address you used to register Pubvar and we will send you a password reset link.</p>
							    	<Form.Item
							    		name="email"
							    		rules={[{ required: true, message: 'Please enter your email address' }]}
							    		>
							        	<Input prefix={<MailOutlined  className="site-form-item-icon" />} placeholder="Enter your email address" />
							    	</Form.Item>
							    	

							      <Form.Item>
							        <Button loading={this.state.loading} type="primary" htmlType="submit" className="login-form-button">
							          Send password reset email
							        </Button>
							        Remember your password? <Link to="/login"> Sign in</Link>
							      </Form.Item>
							    </Form>
			    			</Col>
				    	</Row>
				    </div>
			    </div>
		    </div>
		)
	}
}