import React, {Component} from 'react';
import {Redirect,Link} from 'react-router-dom'
import {Table,message,PageHeader,Button,Modal,Form,Input} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import reqwest from 'reqwest'
import moment from 'moment'; 
import {Header} from '../Header.jsx'
import {Footer} from '../Footer.jsx'
import {CheckLogin} from '../Common.jsx'
import BuildTitle from '../BuildTitle.jsx'

moment.locale('zh-cn');

export class Products extends Component {
	constructor(props) {
	    super(props);
		let token = '';
		let user_name = '';
		let role = '';
		let check_result = CheckLogin();
		if (check_result !== false){
			token = check_result.Authorization;
	    	user_name = check_result.user_name;
	    	role = check_result.role;
		}
		this.state = {
			...props,
			'L1': 'products',
			'token' : token,
			'user_name': user_name,
			'role': role,
			'redirect' : '',
			'list' : [],
			'loading': true,
			'pagination': {
				'pageSize': 20,
				'current' : 1,
				'total' : 0,
			},
			column : [
				{'title':'ID', 'dataIndex':'id', width:50},
				{'title':'项目编号', 'dataIndex':'product_sn', width:120,render:(text,record) => {
					return <Link to={`/product/${record['id']}`}>{text}</Link>
				}},
				{'title':'项目名称', 'dataIndex':'product_name'},
				{'title':'检测时间', 'dataIndex':'tat', width:150},
				{'title':'基因个数', 'dataIndex':'n_genes', width:150},
				{'title':'实验技术', 'dataIndex':'method', width:150},
				{'title':'操作', 'key':'action', width:50, render:(text,record) => {
					return <Button type="link" className="btn-in-table" onClick={ e => {this.removeRecord(record)}}>删除</Button>
				}},
			],
		}
	}
	componentDidMount(){
		if(this.state.token){
			this.fetch({...this.state.pagination});
		}
	}


	getRandomuserParams = (params) => {
		return {
			pageSize: params.pageSize,
			page: params.current,
			...params,
		};
	}

	select_search = (input, option) => {
		for(let i in option.children){
			let str = option.children[i];
			if(str === ''){
				continue;
			}
			if (str.indexOf(input) >= 0){
				return true;
			}
		}
		return false;
	}

	fetch = (params) => {
		reqwest({
			url: `/api/products`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			data:this.getRandomuserParams(params),
			type: 'json',
		}).then(
			(data) => {

				if(data.status === 1){
					this.setState({
						'loading': false,
						'list': data.results.data,
						'pagination': {
			       			'current': params.current,
						    'pageSize': params.pageSize,
			       			'total': data.results.total,
			        	}
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	// 表格发生改变时候
	handleTableChange = (pagination) => {
		console.log(pagination);
		this.setState({
			'loading': true,
		})
		this.fetch({
			current : pagination.current,
			pageSize: pagination.pageSize,
		});
	}

	// 新增
	openNewModal = () => {
		this.setState({
			'new_case_modal': true,
		})
	}
	closeNewModal = () => {
		this.setState({
			'new_case_modal': false,
		})
	}

	pastCase = (data) => {
		reqwest({
			url: '/api/patient',
			method: 'POST',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: data
		}).then(
			(data) => {
				if(data.status === 1){
					let patient_id = data.result;
					message.success('创建成功');
					this.setState({
						'loading':false,
						'redirect': `/case/${patient_id}`,
					})
				}else{
					message.error(data.msg);
					this.setState({'loading':false});
				}
			},
			(err,data) => {
				message.error('请求失败');
				this.setState({'loading':false});
			}
		);
	}
	newCase = () => {
		let that = this;
		let form = this.refs.new_case;
		let patient = form.getFieldsValue();
		if(!patient.name){
			message.error('请填写姓名');
			return false;
		}

		reqwest({
			url: '/api/patient/checkDup',
			method: 'POST',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: patient
		}).then(
			(data) => {
				if(data.status === 1){
					Modal.confirm({
						title: '该样本名称有重，您确认要创建?',
						icon: <ExclamationCircleOutlined />,
						onOk() {
							that.pastCase(patient);
						},
					});
				}else{
					this.pastCase(patient);
				}
			},
			(err,data) => {
				message.error('请求失败');
				this.setState({'loading':false});
			}
		);

	}

	postDelete = (id) => {
		reqwest({
			url: `/api/product/${id}`,
			method: 'DELETE',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('删除成功');
					this.fetch({...this.state.pagination});
				}else{
					message.error(data.msg);
				}
				this.setState({
					'loading': false,
				})
			},
			(err,data) => {
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	removeRecord = (record) => {
		let that = this;
		Modal.confirm({
			title: '您确信要删除该记录?',
			icon: <ExclamationCircleOutlined />,
			onOk() {
				that.postDelete(record.id);
			},
		});
	}

	openNewModal = () =>{
		this.setState({'product_new': true})
	}
	closeNewModal = () =>{
		this.setState({'product_new': false})
	}
	postAdd = () => {
		let form = this.refs.product_new;
		form.validateFields().then((data) => {
			reqwest({
				url: `/api/product`,
				method: 'post',
				headers: {
					'Authorization': this.state.token,
				},
				data:data,
				type: 'json',
			}).then(
				(data) => {
					if(data.status === 1){
						message.success('添加成功');
						this.setState({
							'redirect': `/product/${data.result}`,
						})
					}else{
						message.error(data.msg);
					}
					this.setState({
						'loading': false,
					})
				},
				(err,data) => {
					message.error('Network error');
					this.setState({'loading': false})
				}
			);
		});
	}

	
	render(){
		if(! this.state.token){
			return <Redirect to="/login"/>
		}
		if(this.state.redirect){
			return <Redirect to={this.state.redirect}/>
		}
		return (
			<div>
				<Header {...this.state} />
				<div className="max-width">
					<PageHeader title="样本列表" style={{padding:0,marginTop:10}} 
						extra={[
							<Button key="1" onClick={this.openNewModal} type="primary">新增检测项目</Button>,
						]}
					>
						<Table 
							bordered 
							loading={this.state.loading} 
							rowKey="id" 
							size="small" 
							columns={this.state.column} 
							dataSource={this.state.list}
							onChange={this.handleTableChange}
							pagination={{  //分页
						        total: this.state.pagination.total, //数据总数量
						        pageSize: this.state.pagination.pageSize,  //显示几条一页
						        defaultPageSize: 10, //默认显示几条一页
						        showSizeChanger: true,  //是否显示可以设置几条一页的选项
						        showTotal:  () => {return '共' + this.state.pagination.total + '条记录'; }
						    }}
						    locale={{emptyText:'No Data',}}
						/>
					</PageHeader>
				</div>
				<Modal
		        	title={<BuildTitle visible={this.state.product_new} id="product_new" title="新增检测项目" />}
					visible={this.state.product_new}
					onCancel={this.closeNewModal}
					onOk={this.postAdd}
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('product_new').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="product_new"
						layout="horizontal"
						labelCol = {{ span: 4 }}
						wrapperCol = {{ span: 20 }}
						labelAlign="left"
					>
						<Form.Item name="product_sn" label="项目编号" rules={[{ required: true }]} >
							<Input />
						</Form.Item>
						<Form.Item name="product_name" label="项目名称" rules={[{ required: true }]} >
							<Input />
						</Form.Item>
					</Form>
				</Modal>
				<Footer />
			</div>
		)
	}
}