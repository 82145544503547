import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {Table,message,PageHeader,Button,Modal,Form,Input,Select,Space} from 'antd';
import reqwest from 'reqwest'
import {Header} from '../Header.jsx'
import {Footer} from '../Footer.jsx'
import {CheckLogin} from '../Common.jsx'
import { ExclamationCircleOutlined} from '@ant-design/icons';
import BuildTitle from '../BuildTitle.jsx'


export class Users extends Component {
	constructor(props) {
	    super(props);
		let token = '';
		let user_name = '';
		let role = '';
		let check_result = CheckLogin()
		if (check_result !== false){
			token = check_result.Authorization
	    	user_name = check_result.user_name
	    	role = check_result.role;
		}
		this.state = {
			...props,
			'token' : token,
			'user_name': user_name,
			'role': role,
			'redirect' : '',
			'list' : [],
			'loading': true,
			'pagination': {
				'pageSize': 20,
				'current' : 1,
				'total' : 0,
			},

			'column' : [
				{'title':'姓名', 'dataIndex':'name_chs',width:100},
				{'title':'邮箱', 'dataIndex':'email'},
				{'title':'职位', 'dataIndex':'title'},
				{'title':'手机号码', 'dataIndex':'mobile',width:90},
				{'title':'创建时间', 'dataIndex':'created_at', width:180},
				{'title':'操作', 'key':'action', width:100, render:(item,record) => {
					return (
						<Space>
							<Button type="link" className="btn-in-table" onClick={() => {this.openRemoveModal(record)}}>删除</Button>
							<Button type="link" className="btn-in-table" onClick={() => {this.openEditModal(record)}}>编辑</Button>
						</Space>
					)
				}},
			],

			'edit_record': {},
		}
	}
	componentDidMount(){
		this.fetch({...this.state.pagination});
	}

	getRandomuserParams = (params) => {
		return {
			pageSize: params.pageSize,
			page: params.current,
			...params,
		};
	}
	select_search = (input, option) => {
		for(let i in option.children){
			let str = option.children[i];
			if(str === ''){
				continue;
			}
			if (str.indexOf(input) >= 0){
				return true;
			}
		}
		return false;
	}

	fetch = (params) => {
		reqwest({
			url: `/api/mgm/users`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			data:this.getRandomuserParams(params),
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					this.setState({
						'loading': false,
						'list': data.results.data,
						'pagination': {
			       			'current': params.current,
						    'pageSize': params.pageSize,
			       			'total': data.results.total,
			        	}
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	// 表格发生改变时候
	handleTableChange = (pagination) => {
		this.setState({
			'loading': true,
		})
		this.fetch({
			current : pagination.current,
			pageSize: pagination.pageSize,
		});
	}


	openUserAddModal = () => {
		this.setState({
			'user_add_modal': true,
		})
	}
	closeUserAddModal = () => {
		this.setState({
			'user_add_modal': false,
		})
	}
	postAddUser = (data) =>{
		data['role'] = 'admin';
		reqwest({
			url: `/api/mgm/doctor`,
			method: 'POST',
			headers: {
				'Authorization': this.state.token,
			},
			data:data,
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('添加成功');
					this.setState({
						'user_add_modal':false,
						'loading': true,
					})
					this.fetch({...this.state.pagination});
				}else{
					message.error(data.msg);
				}
			},
			(err,data) => {
				message.error('Network error');
			}
		);
	}
	userAdd = () => {
		let form =this.refs.new_user;
		form.validateFields().then((data) => {
			console.log(data);
			this.postAddUser(data);
		})
	}
	

	openRemoveModal = (record) => {
		let that = this;
		Modal.confirm({
			title: '警告',
			icon: <ExclamationCircleOutlined />,
			content: '您确认删除该用户?',
			onOk() {
				reqwest({
					url: `/api/mgm/doctor`,
					method: 'DELETE',
					headers: {
						'Authorization': that.state.token,
					},
					data:{'id':record['id']},
					type: 'json',
				}).then(
					(data) => {
						if(data.status === 1){
							message.success('删除成功');
							that.fetch({...that.state.pagination});
						}else{
							message.error(data.msg);
						}
					},
					(err,data) => {
						message.error('Network error');
					}
				);
			},
		});

	}

	openEditModal = (record) => {
		this.setState({
			'user_edit_modal': true,
			'edit_record': record
		});
	}
	closeUserEditModal = () => {
		this.setState({
			'user_edit_modal': false,
			'edit_record': {}
		});
	}
	postEditUser = (data) =>{
		data['id'] = this.state.edit_record['id'];
		reqwest({
			url: `/api/mgm/doctor`,
			method: 'PUT',
			headers: {
				'Authorization': this.state.token,
			},
			data:data,
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('添加成功');
					this.setState({
						'user_edit_modal':false,
						'edit_record': {},
						'loading': true,
					})
					this.fetch({...this.state.pagination});
				}else{
					message.error(data.msg);
				}
			},
			(err,data) => {
				message.error('Network error');
			}
		);
	}
	userEdit = () => {
		let form =this.refs.edit_user;
		form.validateFields().then((data) => {
			this.postEditUser(data);
		})
	}

	edit_form = (data) => {
		return (
			<Form
				style={{marginTop:'0px'}}
				ref="edit_user"
				layout="horizontal"
				initialValues={data}
				labelCol = {{ span: 4 }}
				wrapperCol = {{ span: 20 }}
				labelAlign="left"
			>
				<Form.Item name="name_chs" label="姓名" rules={[{ required: true }]} >
					<Input value="" />
				</Form.Item>
				<Form.Item name="institution" label="单位">
					<Input value="" />
				</Form.Item>
				<Form.Item name="institution_type" label="单位类型"  >
					<Select>
						<Select.Option value="医院">医院</Select.Option>
						<Select.Option value="科研机构">科研机构</Select.Option>
						<Select.Option value="公司">公司</Select.Option>
						<Select.Option value="个人">个人</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item name="title" label="职位" >
					<Input value="" />
				</Form.Item>
				<Form.Item name="mobile" label="手机号码" >
					<Input value="" />
				</Form.Item>
				<Form.Item name="tel" label="座机" >
					<Input value="" />
				</Form.Item>
			</Form>
		)
	}

	render(){
		if(! this.state.token){
			return <Redirect to="/login"/>
		}
		if(this.state.role === 'doctor'){
			return <Redirect to="/cases"/>
		}
		if(this.state.role === 'sale'){
			return <Redirect to="/tests"/>
		}
		return (
			<div>
				<Header {...this.state}  L1="users" />
				<div className="max-width">
					<PageHeader title="用户管理" style={{padding:0,marginTop:10}} 
						extra={[
							<Button key="1" onClick={this.openUserAddModal} type="primary">新增用户</Button>,
						]}
					>
						<Table 
							bordered 
							loading={this.state.loading} 
							rowKey="id" 
							size="small" 
							columns={this.state.column} 
							dataSource={this.state.list}
							onChange={this.handleTableChange}
							pagination={{  //分页
						        total: this.state.pagination.total, //数据总数量
						        pageSize: this.state.pagination.pageSize,  //显示几条一页
						        defaultPageSize: 10, //默认显示几条一页
						        showSizeChanger: true,  //是否显示可以设置几条一页的选项
						        showTotal:  () => {return '共' + this.state.pagination.total + '条记录'; }
						    }}
						    locale={{emptyText:'No Data',}}
						/>
					</PageHeader>
				</div>
				<Modal
		        	title={<BuildTitle visible={this.state.user_add_modal} id="user_add_modal1" title="新添用户" />}
					visible={this.state.user_add_modal}
					onCancel={this.closeUserAddModal}
					onOk={this.userAdd}
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('user_add_modal1').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="new_user"
						layout="horizontal"
						initialValues={{}}
						labelCol = {{ span: 4 }}
						wrapperCol = {{ span: 20 }}
						labelAlign="left"
					>
						<Form.Item name="email" label="邮箱" rules={[{ required: true }]} >
							<Input value="" />
						</Form.Item>
					</Form>
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.user_edit_modal} id="user_edit_modal1" title="编辑用户信息" />}
					visible={this.state.user_edit_modal}
					onCancel={this.closeUserEditModal}
					onOk={this.userEdit}
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('user_edit_modal1').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					{this.edit_form(this.state.edit_record)}	
				</Modal>
				<Footer />
			</div>
		)
	}
}