import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {Table,message,PageHeader,Button,Modal,Form,Input,Select,Descriptions,Spin} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import reqwest from 'reqwest'
import moment from 'moment'; 
import {Header} from '../Header.jsx'
import {Footer} from '../Footer.jsx'
import {CheckLogin} from '../Common.jsx'
import BuildTitle from '../BuildTitle.jsx'

moment.locale('zh-cn');

export class Product extends Component {
	constructor(props) {
	    super(props);
		let token = '';
		let user_name = '';
		let role = '';
		let check_result = CheckLogin();
		if (check_result !== false){
			token = check_result.Authorization;
	    	user_name = check_result.user_name;
	    	role = check_result.role;
		}
		this.state = {
			...props,
			'L1': 'products',
			'token' : token,
			'page_loading': false,
			'user_name': user_name,
			'role': role,
			'id' : props.match.params.id,
			'product': {},
			'redirect' : '',
			'loading': true,
			'targets_column': [
				{'title':'ID', 'dataIndex':'id', width:100},
				{'title':'适用范围', 'dataIndex':'value'},
				{'title':'操作', 'key':'action', width:100, render:(text,record,index) => {
					return( 
						<span>
							<Button type="link" className="btn-in-table" onClick={ e => {this.editRecord('targets',index)}}>编辑</Button>
							<Button type="link" className="btn-in-table" onClick={ e => {this.removeRecord('targets',index)}}>删除</Button>
						</span>
					)
				}},
			],
			'limitations_column': [
				{'title':'ID', 'dataIndex':'id', width:100},
				{'title':'局限性', 'dataIndex':'value',},
				{'title':'操作', 'key':'action', width:100, render:(text,record,index) => {
					return( 
						<span>
							<Button type="link" className="btn-in-table" onClick={ e => {this.editRecord('limitations',index)}}>编辑</Button>
							<Button type="link" className="btn-in-table" onClick={ e => {this.removeRecord('limitations',index)}}>删除</Button>
						</span>
					)
				}},
			],
		}
	}
	componentDidMount(){
		if(this.state.token){
			this.fetch();
		}
	}



	select_search = (input, option) => {
		for(let i in option.children){
			let str = option.children[i];
			if(str === ''){
				continue;
			}
			if (str.indexOf(input) >= 0){
				return true;
			}
		}
		return false;
	}

	fetch = () => {
		this.setState({'page_loading': true});
		reqwest({
			url: `/api/product/${this.state.id}`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				this.setState({'page_loading': false});
				if(data.status === 1){
					let targets = [];
					if(data.result['targets']){
						let temp = JSON.parse(data.result['targets']);
						for(let i in temp){
							targets.push({
								'id': parseInt(i)+1,
								'value': temp[i],
							})
						}
					}
					let limitations = [];
					if(data.result['limitations']){
						let temp = JSON.parse(data.result['limitations']);
						for(let i in temp){
							limitations.push({
								'id':parseInt(i)+1,
								'value': temp[i],
							})
						}
					}

					this.setState({
						'loading': false,
						'product': data.result,
						'targets': targets,
						'limitations': limitations,
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				this.setState({'page_loading': false});
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	openBaseModal = () =>{
		this.setState({base_edit: true});
	}
	closeBaseModal = () =>{
		this.setState({base_edit: false});
	}
	baseEdit = () => {
		let form = this.refs.base_edit;
		form.validateFields().then((data) => {
			console.log(data);
			this.postEdit(data, ()=>{
				message.success('修改成功');
				this.setState({
					'base_edit': false,
				})
				this.fetch();
			});
		});
	}



	postEdit = (data, callback) => {
		this.setState({'page_loading': true});
		reqwest({
			url: `/api/product/${this.state.id}`,
			method: 'PUT',
			headers: {
				'Authorization': this.state.token,
			},
			data: data,
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					callback();
				}else{
					message.error(data.msg);
				}
				this.setState({'page_loading': false});
			},
			(err,data) => {
				this.setState({'page_loading': false});
				message.error('请求失败');

			}
		);
	}

	openTargetModal = () => {
		this.setState({target_add: true});
	}
	closeTargetModal = () => {
		this.setState({target_add: false});
	}
	targetAdd = () => {
		let form = this.refs.target_add;
		form.validateFields().then((temp) => {
			let value = temp.target;
			let targets = this.state.targets;
			let data = [];
			for(let i in targets){
				data.push(targets[i].value);
			}
			data.push(value);
			this.postEdit({'targets': JSON.stringify(data)}, ()=>{
				message.success('修改成功');
				this.setState({
					'target_add': false,
				})
				this.fetch();
			});
		});
	}


	openLimitModal = () => {
		this.setState({limit_add: true});
	}
	closeLimitModal = () => {
		this.setState({limit_add: false});
	}
	limitAdd = () => {
		let form = this.refs.limit_add;
		form.validateFields().then((temp) => {
			let value = temp.limit;
			let limitations = this.state.limitations;
			let data = [];
			for(let i in limitations){
				data.push(limitations[i].value);
			}
			data.push(value);
			this.postEdit({'limitations': JSON.stringify(data)}, ()=>{
				message.success('修改成功');
				this.setState({
					'limit_add': false,
				})
				this.fetch();
			});
		});
	}
	

	removeRecord = (key, index) => {
		let that = this;
		Modal.confirm({
			title: '您确信要删除该记录?',
			icon: <ExclamationCircleOutlined />,
			onOk() {
				let item = JSON.parse(that.state.product[key]);
				item.splice(index, 1);
				let data = {};
				data[key] = JSON.stringify(item);
				that.postEdit(data, ()=>{
					message.success('删除成功');
					that.fetch();
				});
			},
		});
	}

	editRecord = (key, index) => {
		let item = JSON.parse(this.state.product[key]);
		this.setState({
			'edit_key': key,
			'edit_index':index,
			'edit_value': item[index],
			'edit_modal' : true,
		})
	}

	closeEditModal = () => {
		this.setState({
			'edit_key': '',
			'edit_index': '',
			'edit_value': '',
			'edit_modal' : false,
		})
	}

	editRecortPost = () => {
		let item = JSON.parse(this.state.product[this.state.edit_key]);

		let form = this.refs.edit_record;
		form.validateFields().then((temp) => {
			let value = temp.value;
			item[this.state.edit_index] = value;
			let data = {};
			data[this.state.edit_key] = JSON.stringify(item);
			this.postEdit(data, ()=>{
				message.success('修改成功');
				this.fetch();
				this.closeEditModal();
			});
		});
	}
	
	render(){
		if(! this.state.token){
			return <Redirect to="/login"/>
		}
		if(this.state.redirect){
			return <Redirect to={this.state.redirect}/>
		}
		return (
			<div>
				<Header {...this.state} />
				<Spin tip="加载中..." spinning={this.state.page_loading}>
					<div className="max-width">
						<PageHeader title="基本信息" style={{padding:0,marginTop:10}} 
							extra={[
								<Button key="1" onClick={this.openBaseModal} type="primary">编辑</Button>,
							]}
						>
							<Descriptions size="small" column={1}  bordered className="table_list">
		        				<Descriptions.Item label="项目编号" >{this.state.product.product_sn} </Descriptions.Item>
		        				<Descriptions.Item label="项目名称" >{this.state.product.product_name} </Descriptions.Item>
		        				<Descriptions.Item label="检测时间" >{this.state.product.tat} </Descriptions.Item>
		        				<Descriptions.Item label="基因个数" >{this.state.product.n_genes} </Descriptions.Item>
		        				<Descriptions.Item label="检测内容" >{this.state.product.content} </Descriptions.Item>
		        				<Descriptions.Item label="实验技术" >{this.state.product.method} </Descriptions.Item>
		        				<Descriptions.Item label="样本要求" >{this.state.product.sample_requirement} </Descriptions.Item>
		        			</Descriptions>
						</PageHeader>
						<PageHeader title="适用范围" style={{padding:0,marginTop:10}} 
							extra={[
								<Button key="1" onClick={this.openTargetModal} type="primary">新增适用范围</Button>,
							]}
						>
							<Table  
								bordered 
								rowKey="id" 
								size="small" 
								columns={this.state.targets_column} 
								dataSource={this.state.targets}
								pagination={false}
								locale={{emptyText:'No Data',}}
							/>
						</PageHeader>

						<PageHeader title="局限性" style={{padding:0,marginTop:10}} 
							extra={[
								<Button key="1" onClick={this.openLimitModal} type="primary">新增局限性</Button>,
							]}
						>
							<Table  
								bordered 
								rowKey="id"
								size="small" 
								columns={this.state.limitations_column} 
								dataSource={this.state.limitations}
								pagination={false}
								locale={{emptyText:'No Data',}}
							/>
						</PageHeader>
					</div>
					<Modal
			        	title="编辑"
						visible={this.state.base_edit}
						onCancel={this.closeBaseModal}
						onOk={this.baseEdit}
						maskClosable={false}
						destroyOnClose={true}
						width="800px"
					>
						<Form
							style={{marginTop:'0px'}}
							ref="base_edit"
							layout="horizontal"
							initialValues={this.state.product}
							labelCol = {{ span: 4 }}
							wrapperCol = {{ span: 20 }}
							labelAlign="left"
						>
							<Form.Item name="product_sn" label="项目编号" rules={[{ required: true }]} >
								<Input />
							</Form.Item>
							<Form.Item name="product_name" label="项目名称" rules={[{ required: true }]} >
								<Input />
							</Form.Item>
							<Form.Item name="tat" label="检测时间">
								<Input />
							</Form.Item>
							<Form.Item name="n_genes" label="检测基因个数">
								<Input />
							</Form.Item>
							<Form.Item name="content" label="检测内容">
								<Input.TextArea />
							</Form.Item>
							<Form.Item name="method" label="实验技术">
								<Select>
									<Select.Option value="一代测序">一代测序</Select.Option>
									<Select.Option value="二代测序">二代测序</Select.Option>
									<Select.Option value="毛细管电泳">毛细管电泳</Select.Option>
									<Select.Option value="MLPA">MLPA</Select.Option>
								</Select>
							</Form.Item>
							<Form.Item name="sample_requirement" label="送样要求">
								<Input />
							</Form.Item>
						</Form>
					</Modal>
					<Modal
		        		title={<BuildTitle visible={this.state.target_add} id="target_add" title="新增适用范围" />}
						visible={this.state.target_add}
						onCancel={this.closeTargetModal}
						onOk={this.targetAdd}
						maskClosable={false}
						destroyOnClose={true}
						afterClose={() => {
				            try {
								document.getElementById('target_add').style.transform = "translate(0px, 0px)";
							} catch (e) {}
						}}
					>
						<Form
							style={{marginTop:'0px'}}
							ref="target_add"
							layout="horizontal"
							labelCol = {{ span: 4 }}
							wrapperCol = {{ span: 24 }}
							labelAlign="left"
						>
							<Form.Item name="target" label="" rules={[{ required: true }]} >
								<Input.TextArea />
							</Form.Item>
						</Form>
					</Modal>

					<Modal
		        		title={<BuildTitle visible={this.state.limit_add} id="target_add" title="新增局限性" />}
						visible={this.state.limit_add}
						onCancel={this.closeLimitModal}
						onOk={this.limitAdd}
						maskClosable={false}
						destroyOnClose={true}
						afterClose={() => {
				            try {
								document.getElementById('limit_add').style.transform = "translate(0px, 0px)";
							} catch (e) {}
						}}
					>
						<Form
							style={{marginTop:'0px'}}
							ref="limit_add"
							layout="horizontal"
							labelCol = {{ span: 4 }}
							wrapperCol = {{ span: 24 }}
							labelAlign="left"
						>
							<Form.Item name="limit" label="" rules={[{ required: true }]} >
								<Input.TextArea />
							</Form.Item>
						</Form>
					</Modal>

					<Modal
		        		title={<BuildTitle visible={this.state.edit_modal} id="edit_record_modal" title="编辑记录" />}
						visible={this.state.edit_modal}
						onCancel={this.closeEditModal}
						onOk={this.editRecortPost}
						maskClosable={false}
						destroyOnClose={true}
						afterClose={() => {
				            try {
								document.getElementById('edit_record_modal').style.transform = "translate(0px, 0px)";
							} catch (e) {}
						}}
					>
						<Form
							style={{marginTop:'0px'}}
							ref="edit_record"
							layout="horizontal"
							initialValues={{value:this.state.edit_value}}
							labelCol = {{ span: 4 }}
							wrapperCol = {{ span: 24 }}
							labelAlign="left"
						>
							<Form.Item name="value" label="" rules={[{ required: true }]} >
								<Input.TextArea />
							</Form.Item>
						</Form>
					</Modal>
				</Spin>
				<Footer />
			</div>
		)
	}
}