import React,{ Component } from "react";
import { Router,Route } from 'react-router-dom';

import {Login} from './auth/Login.jsx'
import {Logout} from './auth/Logout.jsx'
import {Sendmail} from './auth/Sendmail.jsx'
import {Reset} from './auth/Reset.jsx'

import {Setting} from './Setting.jsx'


import {MgmCases} from './mgm/Cases.jsx'
import {MgmCase} from './mgm/Case.jsx'
import {MgmTests} from './mgm/Tests.jsx'
import {MgmTest} from './mgm/Test.jsx'
import {Samples} from './mgm/Samples.jsx'
import {Products} from './mgm/Products.jsx'
import {Product} from './mgm/Product.jsx'
import {Doctors} from './mgm/Doctors.jsx'
import {Sales} from './mgm/Sales.jsx'

import {Users} from './mgm/Users.jsx'
import {Apply} from './mgm/Apply.jsx'


import Genes from './crog/Genes.jsx'
import Gene from './crog/Gene.jsx'
import Diseases from './crog/Diseases.jsx'
import Disease from './crog/Disease.jsx'
import Hpos from './crog/Hpos.jsx'
import Hpo from './crog/Hpo.jsx'
import Variants from './crog/Variants.jsx'
import Variant from './crog/Variant.jsx'


import { createHashHistory } from 'history';
const history = createHashHistory();

export class Main extends Component{
	render(){
		return (
			<Router history={history}>
				
				<Route exact path="/login"  render={(props) => (<Login  />) }  />
				<Route exact path="/logout"  render={ (props) => (<Logout />) } />
				<Route exact path="/password/reset"  render={ (props) => (<Sendmail />) } />
				<Route exact path="/password/reset/:token"  render={ (props) => (<Reset {...props} />) } />

				<Route exact path="/setting"  render={ (props) => (<Setting />) } />

				<Route exact path="/" render={(props) => (<MgmCases />) }  />
				<Route exact path="/cases" render={(props) => (<MgmCases />) }  />
				<Route exact path="/case/:id" render={ (props) => (<MgmCase {...props} />) }  />
				<Route exact path="/tests" render={(props) => (<MgmTests />) }  />
				<Route exact path="/test/:id" render={ (props) => (<MgmTest {...props} />) }  />
				<Route exact path="/samples" render={(props) => (<Samples />) }  />
				<Route exact path="/products" render={(props) => (<Products  />) }  />
				<Route exact path="/product/:id" render={(props) => (<Product {...props} />) }  />
				<Route exact path="/doctors" render={(props) => (<Doctors />) }  />
				<Route exact path="/sales" render={(props) => (<Sales />) }  />
				<Route exact path="/users" render={(props) => (<Users />) }  />
				<Route exact path="/apply" render={(props) => (<Apply />) }  />

				<Route exact path="/crog/genes" render={(props) => (<Genes />) }  />
				<Route exact path="/crog/gene/:gene" render={(props) => (<Gene {...props} />) }  />
				<Route exact path="/crog/diseases" render={(props) => (<Diseases />) }  />
				<Route exact path="/crog/disease/:disease_id" render={(props) => (<Disease {...props} />) }  />
				<Route exact path="/crog/hpos" render={(props) => (<Hpos />) }  />
				<Route exact path="/crog/hpo/:hpo_id" render={(props) => (<Hpo {...props} />) }  />
				<Route exact path="/crog/variants" render={(props) => (<Variants />) }  />
				<Route exact path="/crog/variant/:pubvar_id" render={(props) => (<Variant {...props} />) }  />

			</Router>
		)
    }
}
