import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {Menu} from 'antd';

export default class Nav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
			'redirect' : '',
		}
	}

	navChange = (value) => {
		let d_route = {
			'genes' : '/crog/genes',
			'diseases' : '/crog/diseases',
			'hpos' : '/crog/hpos',
			'variants' : '/crog/variants',
			'cnv' : '/crog/cnv',
			'gene_panel' : '/crog/gene_panel',
		};
		if(value.key === this.state.L2){
			return false;
		}
		this.setState({'redirect': d_route[value.key]});
	}

	render() {
		if(this.state.redirect){
			return <Redirect to={this.state.redirect}/>
		}
		return (
			<Menu mode="horizontal" onClick={this.navChange} selectedKeys={[this.state.L2]}>
				<Menu.Item key="genes" style={{'marginLeft':0}}>基因</Menu.Item>
				<Menu.Item key="diseases">疾病</Menu.Item>
				<Menu.Item key="hpos">HPO</Menu.Item>
				<Menu.Item key="variants">Variants</Menu.Item>
				<Menu.Item key="cnv">CNV</Menu.Item>
				<Menu.Item key="gene_panel">Gene panel</Menu.Item>
			</Menu>
		)
	}
}