import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom'
import {Table,message,PageHeader,Button,Input,Space} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
// import zhCN from 'antd/es/locale/zh_CN';
import reqwest from 'reqwest'
import moment from 'moment'; 
import Highlighter from 'react-highlight-words';
import {Header} from '../Header.jsx'
import {Footer} from '../Footer.jsx'
import Nav from './Nav.jsx'
import {CheckLogin} from '../Common.jsx'
// import BuildTitle from '../BuildTitle.jsx'

moment.locale('zh-cn');


export default class Hpos extends Component {
	constructor(props) {
	    super(props);
		let token = '';
		let user_name = '';
		let role = '';
		let check_result = CheckLogin();
		if (check_result !== false){
			token = check_result.Authorization;
	    	user_name = check_result.user_name;
	    	role = check_result.role;
		}
		this.state = {
			...props,
			'L1': 'crog',
			'L2' : 'hpos',
			'token' : token,
			'user_name': user_name,
			'role': role,
			'redirect' : '',
			'list' : [],
			'loading': true,
			'filter': {},
			'pagination': {
				'pageSize': 20,
				'current' : 1,
				'total' : 0,
			},
			column : [
				{'title':'HPO id', 'dataIndex':'id', ...this.getColumnSearchProps('id')},
				{'title':'中文', 'dataIndex':'name_chs', ...this.getColumnSearchProps('name_chs')},
				{'title':'英文', 'dataIndex':'name', ...this.getColumnSearchProps('name')},
				{'title':'创建日期', 'dataIndex':'date_created',width:180},
				{'title':'最后修改时间', 'dataIndex':'date_last_update',width:180},
				{'title':'状态', 'dataIndex':'status',width:105, render:(text) => {
					if(text === 1){ return '未校对';}
					if(text === 2){ return '校对中';}
					if(text === 3){ return '完成校对';}
					if(text === 4){ return '评审通过';}
					if(text === 9){ return '已废弃';}
					return text;
				}},
			],
		}
	}
	componentDidMount(){
		if(this.state.token){
			this.fetch({...this.state.pagination});
		}
	}


	getRandomuserParams = (params) => {
		return {
			pageSize: params.pageSize,
			page: params.current,
			...params,
			...this.state.filter,
		};
	}

	select_search = (input, option) => {
		for(let i in option.children){
			let str = option.children[i];
			if(str === ''){
				continue;
			}
			if (str.indexOf(input) >= 0){
				return true;
			}
		}
		return false;
	}

	fetch = (params) => {
		reqwest({
			url: `/api/crog/hpos`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			data:this.getRandomuserParams(params),
			type: 'json',
		}).then(
			(data) => {

				if(data.status === 1){
					this.setState({
						'loading': false,
						'list': data.results.data,
						'pagination': {
			       			'current': params.current,
						    'pageSize': params.pageSize,
			       			'total': data.results.total,
			        	}
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	// 表格发生改变时候
	handleTableChange = (pagination) => {
		this.setState({
			'loading': true,
		})
		this.fetch({
			current : pagination.current,
			pageSize: pagination.pageSize,
		});
	}

	getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.tableFilter(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => this.tableFilter(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						Search
					</Button>
					<Button onClick={() => this.fieldReset(dataIndex,clearFilters)} size="small" style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		// onFilter: (value, record) =>
		// 		record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: text => {
			let link = '';
			if (dataIndex === 'id'){
				link = <Link to={"/crog/hpo/"+text.toString()}>{text}</Link>
			}else{
				link = text;
			}
			return this.state.searchedColumn === dataIndex ? (
				<Highlighter
				  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
				  searchWords={[this.state.searchText]}
				  autoEscape
				  textToHighlight={link}
				/>
			) : link
		}
	})

	tableFilter = (selectedKeys, confirm, dataIndex) => {
		confirm();
		let filter = this.state.filter;
		filter[dataIndex] = selectedKeys[0];
		this.setState({'filter': filter});
		this.fetch({
			current : 1,
			pageSize: this.state.pagination.pageSize,
		});
	}

	fieldReset = (dataIndex, clearFilters) => {
		clearFilters();
		let filter = this.state.filter;
		delete(filter[dataIndex]);
		this.fetch({
			current : 1,
			pageSize: this.state.pagination.pageSize,
		});
	}
	
	render(){
		if(! this.state.token){
			return <Redirect to="/login"/>
		}
		if(this.state.redirect){
			return <Redirect to={this.state.redirect}/>
		}
		return (
			<div>
				<Header {...this.state} />
				<div className="max-width">
					<Nav L2={this.state.L2} />
					<PageHeader title="HPO列表" style={{padding:0,marginTop:10}} >
						<Table 
							bordered 
							loading={this.state.loading} 
							rowKey="id" 
							size="small" 
							columns={this.state.column} 
							dataSource={this.state.list}
							onChange={this.handleTableChange}
							expandable={false}
							pagination={{  //分页
						        total: this.state.pagination.total, //数据总数量
						        pageSize: this.state.pagination.pageSize,  //显示几条一页
						        defaultPageSize: 10, //默认显示几条一页
						        showSizeChanger: true,  //是否显示可以设置几条一页的选项
						        showTotal:  () => {return '共' + this.state.pagination.total + '条记录'; }
						    }}
						    locale={{emptyText:'No Data',}}
						/>
					</PageHeader>
	        		

				</div>
				<Footer />
			</div>
		)
	}
}