import React, {Component} from 'react';
import {Redirect,Link} from 'react-router-dom'
import {Table,message,PageHeader,Button,Modal,Form,Select,Input,Tooltip,DatePicker} from 'antd';
import reqwest from 'reqwest'
import Highlighter from 'react-highlight-words';
import moment from 'moment'; 
import {SearchOutlined} from '@ant-design/icons';
import zhCN from 'antd/es/locale/zh_CN';
import {Header} from '../Header.jsx'
import {Footer} from '../Footer.jsx'
import {CheckLogin} from '../Common.jsx'
import {ExclamationCircleOutlined} from '@ant-design/icons';
import BuildTitle from '../BuildTitle.jsx'
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
const conclusion = {
	'1' : ['阳性', 'red'],
	'2' : ['不明确', 'gray'],
	'3' : ['阴性', '#50BC80'],
	'9' : ['其他', 'gray']
};
const conclusion_tips = {
	'1' : '阳性，检测到能解释患者临床表现的致病或疑似致病变异',
	'2' : '检测到和患者表型相关的变异，但不能解释患者的临床表现',
	'3' : '未检测到和患者表型相关的变异',
	'9' : '其他'
};
export class MgmTests extends Component {
	constructor(props) {
	    super(props);
		let token = '';
		let user_name = '';
		let role = '';
		let check_result = CheckLogin()
		if (check_result !== false){
			token = check_result.Authorization
	    	user_name = check_result.user_name
	    	role = check_result.role;
		}
		this.state = {
			...props,
			'token' : token,
			'user_name': user_name,
			'role': role,
			'redirect' : '',
			'list' : [],
			'loading': true,
			'pagination': {
				'pageSize': 20,
				'current' : 1,
				'total' : 0,
			},

			'patient_list': [],
			'doctor_list': [],
			'product_list': [],
			'sale_list': [],

			'column' : [
				{'title':'检测编号', 'dataIndex':'test_sn',width:100,render:(text,record) => {
					let tip = '';
					if(record['new_pat_found'] === '1' && record['new_gene_found'] !== '1'){
						tip = '发现或可能发现新的致病变异';
					}
					if(record['new_pat_found'] !== '1' && record['new_gene_found'] === '1'){
						tip = '发现新的致病基因';
					}
					if(record['new_pat_found'] === '1' && record['new_gene_found'] === '1'){
						tip = '发现或可能发现新的致病变异或基因';
					}
					return (
						<div>
							{tip !== '' ? <Tooltip title={tip}><i className="iconfont icon-new discover-new"></i></Tooltip> : ''}
							<Link to={`/test/${record['id']}`} target="_blank" >{text}
							</Link>
						</div>
					)
				}},
				{'title':'姓名', 'dataIndex':'name',width:90, ...this.getColumnSearchProps('name')},
				{'title':'检查项目', 'dataIndex':'product_name'},
				{'title':'金额', 'dataIndex':'charge',width:70},
				{'title':'医生', 'dataIndex':'doctor_name',width:80, ...this.getColumnSearchProps('doctor_name')},
				// {'title':'业务员', 'dataIndex':'sale_name',width:70},
				
				{'title':'送样时间', 'dataIndex':'date_sample_collected', width:120, sorter: (a, b) => a > b,},
				{'title':'状态', 'dataIndex':'status', width:90, render:(text) => {
					if(text === '已完成'){
						return <span style={{'color':'rgb(80, 188, 128)'}}>{text}</span>
					}else{
						return text;
					}
				}},
				{'title':'结论', 'dataIndex':'conclusion', render:(text) => {
					return <Tooltip title={conclusion_tips[text] ? conclusion_tips[text] : text}>{conclusion[text] ? <span style={{color:conclusion[text][1]}}>{conclusion[text][0]}</span> : text}</Tooltip>
				}},
				{'title':'操作', 'key':'action', width:50, render:(text,record) => {
					return <Button type="link" className="btn-in-table" onClick={ e => {this.removeRecord(record)}}>删除</Button>
				}},
			],
		}
	}
	componentDidMount(){
		this.fetch({...this.state.pagination});
		this.dataForTestCreate();
	}

	dataForTestCreate = () => {
		reqwest({
			url: `/api/mgm/dataForTestCreate`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					this.setState({
						'patient_list': data.patients,
						'product_list': data.products,
						'doctor_list': data.doctors,
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	getRandomuserParams = (params) => {
		return {
			pageSize: params.pageSize,
			page: params.current,
			...params,
		};
	}

	fetch = (params) => {
		reqwest({
			url: `/api/mgm/tests`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			data:this.getRandomuserParams(params),
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					this.setState({
						'loading': false,
						'list': data.results.data,
						'pagination': {
			       			'current': params.current,
						    'pageSize': params.pageSize,
			       			'total': data.results.total,
			        	}
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	// 表格发生改变时候
	handleTableChange = (pagination) => {
		this.setState({
			'loading': true,
		})
		this.fetch({
			current : pagination.current,
			pageSize: pagination.pageSize,
		});
	}

	select_search = (input, option) => {
		for(let i in option.children){
			let str = option.children[i];
			str += ''; 
			if(str === ''){
				continue;
			}
			if (str.indexOf(input) >= 0){
				return true;
			}
		}
		return false;
	}

	// 受检者的变更后，如果该受检者有对应的数组，则无需再选择医生
	changeDoctorID = (value) => {
		for(let i in this.state.patient_list){
			let d = this.state.patient_list[i];
			if(d['id'] === value){
				if(d['user_id']){
					this.refs.new_case.setFieldsValue({'doctor_id': d['user_id']});
					this.setState({
						'doctor_field_disabled' : true
					})
					this.getDoctorSale(d['user_id']);
				}else{
					this.refs.new_case.setFieldsValue({'doctor_id': ''});
					this.setState({
						'doctor_field_disabled' : false
					})
				}
				break;
			}
		}
	}

	// 医生的变更触发去获取该医生对应的销售
	getDoctorSale = (value) => {
		this.setState({'sale_loading':true});
		if(this.refs.edit_test_base !== undefined){
			this.refs.edit_test_base.setFieldsValue({'saleman_id': ''});
		}
		if(!value){
			this.setState({'sale_list':[],'sale_loading':false});
			return true;
		}
		reqwest({
			url: `/api/mgm/doctor/${value}/sales`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					this.setState({
						'sale_list': data.results,
						'sale_loading':true
					})
				}else{
					message.error(data.msg);
				}
			},
			(err,data) => {
				message.error('请求失败');
			}
		);
	}

	// 新增
	openNewModal = () => {
		this.setState({
			'new_test_modal': true,
		})
	}
	closeNewModal = () => {
		this.setState({
			'new_test_modal': false,
		})
	}
	newTest = () => {
		let form = this.refs.new_case;
		form.validateFields().then((data) => {
			if(!data.patient_id){
				message.error('请选择一个病人/受检者信息');
				return false;
			}
			data['date_sample_collected'] = moment(data['date_sample_collected']).format('YYYY-MM-DD');
			
			reqwest({
				url: '/api/mgm/test',
				method: 'POST',
				headers: {
					'Authorization': this.state.token,
				},
				type: 'json',
				data: data
			}).then(
				(data) => {
					if(data.status === 1){
						let test_id = data.result;
						message.success('创建成功');
						this.setState({
							'loading':false,
							'redirect': `/test/${test_id}`,
						})
					}else{
						message.error(data.msg);
						this.setState({'loading':false});
					}
				},
				(err,data) => {
					message.error('请求失败');
					this.setState({'loading':false});
				}
			);
		})

	}


	testDelete = (id) => {
		reqwest({
			url: `/api/mgm/test/${id}`,
			method: 'DELETE',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('删除成功');
					this.fetch({...this.state.pagination});
				}else{
					message.error(data.msg);
				}
				this.setState({
					'loading': false,
				})
			},
			(err,data) => {
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	removeRecord = (record) => {
		let that = this;
		Modal.confirm({
			title: '您确信要删除该检查?',
			icon: <ExclamationCircleOutlined />,
			onOk() {
				that.testDelete(record.id);
			},
		});
	}


	// 表头筛选事件
	getColumnSearchProps = dataIndex => ({
	    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
		        <Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90, marginRight: 8 }}
				>
		          Search
		        </Button>
				<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
	    ),
	    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  //   onFilter: (value, record) => {
	  //   	if(record[dataIndex] === null){
	  //   		return false;
	  //   	}
			// return record[dataIndex]
			// 	.toString()
			// 	.toLowerCase()
			// 	.includes(value.toLowerCase());
	  //   },
	    onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}	
		},
		render: (text,record) => {
			return this.state.searchedColumn === dataIndex ? (
				dataIndex === 'hg19_name' ? (
					<Link to={'/variant/'+text.replace(/[:/]/g,'_')} target="_blank">
					<Highlighter
						highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
						searchWords={[this.state.searchText]}
						autoEscape
						textToHighlight={text.toString()}
					/>
					</Link>
				) : (
					<Highlighter
						highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
						searchWords={[this.state.searchText]}
						autoEscape
						textToHighlight={text.toString()}
					/>
				)
			) : ( dataIndex === 'hg19_name' ? <Link to={'/variant/'+text.replace(/[:/]/g,'_')} target="_blank">{text}</Link>  : text )
		},
	});
	// 表头发起搜索
    handleSearch = (selectedKeys, confirm, dataIndex) => {
	    confirm();
	    this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
	    });
	};
	// 重置搜索
	handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	// 表格发生改变时候
	handleTableChange = (pagination, filters, sorter) => {
		this.setState({
			sortField: sorter.field,
			sortOrder: sorter.order,
			'filter' : filters
		})
		this.fetch({
			sortField: sorter.field,
			sortOrder: sorter.order,
			current : pagination.current,
			pageSize: pagination.pageSize,
			...filters,
		});
	};
	
	render(){
		if(! this.state.token){
			return <Redirect to="/login"/>
		}
		if(this.state.role === 'doctor'){
			return <Redirect to="/cases"/>
		}
		if(this.state.role === 'sale'){
			return <Redirect to="/tests"/>
		}
		if(this.state.redirect){
			return <Redirect to={this.state.redirect}/>
		}
		return (
			<div>
				<Header {...this.state} L1="tests" />
				<div className="max-width">
					<PageHeader title="检测列表" style={{padding:0,marginTop:10}} 
						extra={[
							<Button key="1" type="primary" onClick={this.openNewModal}>新建检测</Button>,
						]}
					>
						<Table 
							bordered 
							loading={this.state.loading} 
							rowKey="id" 
							size="small" 
							columns={this.state.column} 
							dataSource={this.state.list}
							onChange={this.handleTableChange}
							pagination={{  //分页
						        total: this.state.pagination.total, //数据总数量
						        pageSize: this.state.pagination.pageSize,  //显示几条一页
						        defaultPageSize: 10, //默认显示几条一页
						        showSizeChanger: true,  //是否显示可以设置几条一页的选项
						        showTotal:  () => {return '共' + this.state.pagination.total + '条记录'; }
						    }}
						    locale={{emptyText:'No Data',}}
						/>
					</PageHeader>
				</div>
				<Modal
		        	title={<BuildTitle visible={this.state.new_test_modal} id="new_test_modal" title="新建检测" />}
					visible={this.state.new_test_modal}
					onCancel={this.closeNewModal}
					onOk={this.newTest}
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('new_test_modal').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="new_case"
						layout="horizontal"
						initialValues={{}}
						labelCol = {{ span: 6 }}
						wrapperCol = {{ span: 18 }}
						labelAlign="left"
					>
						<Form.Item name="patient_id" label="病人/受检者" rules={[{ required: true , message: '请选择一个病人' }]}>
							<Select
								showSearch
								placeholder="请选择一个病人/受检者"
								optionFilterProp=""
								filterOption={this.select_search}
								onChange={this.changeDoctorID}
							>
								{
									this.state.patient_list.map((item,index) => {
										return <Select.Option key={item['id']} value={item['id']}>{item['patient_sn']} {item['name']}</Select.Option>
									})
								}
							</Select>
						</Form.Item>
						<Form.Item name="product_id" label="检测项目" rules={[{ required: true, message: '请选择一个项目' }]}>
							<Select
								showSearch
								placeholder="请选择一个项目"
								optionFilterProp=""
								filterOption={this.select_search}
							>
								{
									this.state.product_list.map((item,index) => {
										return <Select.Option key={item['id']} value={item['id']}>{item['product_sn']} {item['product_name']}</Select.Option>
									})
								}
							</Select>
						</Form.Item>
						<Form.Item name="charge" label="收费" rules={[{ required: true ,message : '请填写收费金额'}]}>
							<Input />
						</Form.Item>

						<Form.Item name="date_sample_collected" label="送样时间" rules={[{ required: true, message:'请填写该检测的送样时间' }]}>
							<DatePicker locale={zhCN.DatePicker}  />
						</Form.Item>
						
						<Form.Item name="doctor_id" label="医生">
							<Select
								showSearch
								placeholder="请选择一个医生"
								optionFilterProp=""
								filterOption={this.select_search}
								onChange={this.getDoctorSale}
								disabled={this.state.doctor_field_disabled}
							>
								{
									this.state.doctor_list.map((item,index) => {
										return <Select.Option key={item['id']} value={item['id']}>{item['name_chs']} {item['institution']}</Select.Option>
									})
								}
							</Select>
						</Form.Item>
						<Form.Item name="saleman_id" label="业务员">
							<Select
								showSearch
								placeholder="请选择一个业务员"
								optionFilterProp=""
								filterOption={this.select_search}
								loading={this.state.sale_loading}
							>
								{
									this.state.sale_list.map((item,index) => {
										return <Select.Option key={item['id']} value={item['id']}>{item['name_chs']} {item['institution']}</Select.Option>
									})
								}
							</Select>
						</Form.Item>
						
						
					</Form>
				</Modal>
				<Footer />
			</div>
		)
	}
}