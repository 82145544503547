import React from "react";
import {render} from "react-dom";
import {Main} from './route.jsx'
import 'antd/dist/antd.css';
import './index.css'
import './css/iconfont.css'




render((<Main />), document.getElementById('root'))

