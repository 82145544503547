import React, {Component} from 'react';
import {Redirect,Link} from 'react-router-dom'
import {Table,message,PageHeader,Button,Modal,Form,Input,Space,Select,Descriptions,Tag,Comment,Breadcrumb} from 'antd';
import {ExclamationCircleOutlined,CloseOutlined,PlusOutlined,UserOutlined} from '@ant-design/icons';
// import zhCN from 'antd/es/locale/zh_CN';
import reqwest from 'reqwest'
import moment from 'moment'; 
// import Highlighter from 'react-highlight-words';
import {Header} from '../Header.jsx'
import {Footer} from '../Footer.jsx'
import Nav from './Nav.jsx'
import {CheckLogin} from '../Common.jsx'
import BuildTitle from '../BuildTitle.jsx'

moment.locale('zh-cn');


let transcript_column = [
	{'title':'Transcript ID', 'dataIndex':'transcript_id'},
	{'title':'hg19位置', 'dataIndex':'transcript_position',render:(text,record) => {
		return record['chrom']+':'+record['transcript_position']+':'+record['transcript_strand']
	}},
	{'title':'外显子数目', 'dataIndex':'exon_number'},
	{'title':'转录本长度', 'dataIndex':'transcript_length'},
	{'title':'编码区长度', 'dataIndex':'cds_length'},
];

let hpo_column = [
	{'title':'HPO', 'dataIndex':'id'},
	{'title':'中文名称', 'dataIndex':'name_chs'},
	{'title':'出现频率', 'dataIndex':'frequency'},
	{'title':'核心表型', 'dataIndex':''},
];

export default class Genes extends Component {
	constructor(props) {
	    super(props);
		let token = '';
		let user_name = '';
		let role = '';
		let check_result = CheckLogin();
		if (check_result !== false){
			token = check_result.Authorization;
	    	user_name = check_result.user_name;
	    	role = check_result.role;
		}
		this.state = {
			...props,
			'L1': 'crog',
			'L2' : 'genes',
			'token' : token,
			'user_name': user_name,
			'role': role,
			'redirect' : '',
			'gene' : props.match.params.gene,
			'gene_info': {},
			'diseases': [],
			'disease_column': [
				{'title':'Source', 'dataIndex':'source'},
				{'title':'基因名称', 'dataIndex':'gene_symbol'},
				{'title':'疾病ID', 'dataIndex':'disease_id'},
				{'title':'疾病英文名', 'dataIndex':'disease_name'},
				{'title':'Inheritance', 'dataIndex':'inheritance'},
				{'title':'OMIM mapping key', 'dataIndex':'OMIM_mapping_key'},
				{'title':'Evidence level', 'dataIndex':''},
				{'title':'操作', 'key':'action', render:(text,record) => {
					return (<Space>
						<Button type="link" className="btn-in-table" onClick={() => {this.openAddDisease()}}>编辑</Button>
					</Space>)
				}},
			],
			'transcripts': [],
			'hpos': [],
			'loading': true,
		}
	}
	componentDidMount(){
		if(this.state.token){
			this.fetch();
			this.getDisease();
			this.getTranscript();
		}
	}


	fetch = () => {
		reqwest({
			url: `/api/crog/gene/${this.state.gene}`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					this.setState({
						'loading': false,
						'gene_info': data.result,
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	getDisease = () => {
		reqwest({
			url: `/api/crog/gene/${this.state.gene}/disease`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					let this_disease_id = '';

					if(data.results.length > 0 && data.results[0]['disease_id']){
						this_disease_id = data.results[0]['disease_id'];
						this.getDiseaseHpo(this_disease_id)
					}
					this.setState({
						'loading': false,
						'diseases': data.results,
						'this_disease_id' : this_disease_id
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	getDiseaseHpo = (disease_id) => {
		reqwest({
			url: `/api/crog/disease/${disease_id}/hpo`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {

				if(data.status === 1){
					this.setState({
						'loading': false,
						'hpos': data.results,
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	getTranscript = () => {
		reqwest({
			url: `/api/crog/gene/${this.state.gene}/transcript`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {

				if(data.status === 1){
					this.setState({
						'loading': false,
						'transcripts': data.results,
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	status_view = () => {
		let status = this.state.gene_info['status'];
		if(!status){
			return ''
		}
		if(status === 1){ return '未校对';}
		if(status === 2){ return '校对中';}
		if(status === 3){ return '完成校对';}
		if(status === 4){ return '评审通过';}
		if(status === 9){ return '已废弃';}
		return status;
	}

	removeTag = (tag) => {
		let that = this;
		Modal.confirm({
			title: '您确信要删除该别名?',
			icon: <ExclamationCircleOutlined />,
			onOk() {
				that.postTagRemove(tag);
			},
		});
		return false;
	}
	postTagRemove = (tag) => {
		reqwest({
			url: `/api/crog/gene/${this.state.gene}/alias`,
			method: 'DELETE',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {'alias' : tag}
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('添加成功');
					this.fetch()
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);

	}


	showInput = () =>{
		this.setState({
			'alias_input': true
		}, () => {
			this.refs.alias_input.focus()
		})
	}
	clearInput = () => {
		this.setState({
			'alias_input': false
		})
	}
	addNewAlias = (e) => {
		let value = e.target.value;
		if(!value){
			message.error('请输入基因别名');
			return true;
		}
		if(this.state.gene_info['alias'].indexOf(value) !== -1){
			message.error('您输入的别名已经存在');
			return true;
		}
		reqwest({
			url: `/api/crog/gene/${this.state.gene}/alias`,
			method: 'POST',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {'alias' : value}
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('添加成功');
					this.fetch()
					this.setState({'alias_input': false})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	changeDisease = (value) => {
		this.setState({'this_disease_id': value})
		this.getDiseaseHpo(value);
	}

	openAddDisease = () => {
		message.error('该功能还未开发');
	}



	showTranscriptInput = () => {
		this.setState({'edit_canonical_transcript': true, 'canonical_transcript': this.state.gene_info['canonical_transcript']})
	}
	closeTranscriptInput = () => {
		this.setState({'edit_canonical_transcript': false})
	}
	canonicalTranscriptChange = (value) => {
		this.setState({'canonical_transcript': value})
	}
	postCanonicalTranscriptChange = () => {
		reqwest({
			url: `/api/crog/gene/${this.state.gene}/transcript`,
			method: 'PATCH',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {'transcript' : this.state.canonical_transcript}
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('添加成功');
					this.fetch()
					this.setState({'edit_canonical_transcript': false})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	openCommentModal = () => {
		this.setState({'add_comment_modal': true});
	}
	closeCommentModal = () => {
		this.setState({'add_comment_modal': false});
	}
	addComment = () => {
		let form =this.refs.add_comment;
		let that = this;
		form.validateFields().then((data) => {
			if(! data['comment']){
				message.error('请输入评论');
				return false;
			}

			reqwest({
				url: `/api/crog/gene/${that.state.gene}/comment`,
				method: 'POST',
				headers: {
					'Authorization': that.state.token,
				},
				type: 'json',
				data: data
			}).then(
				(data) => {
					if(data.status === 1){
						message.success('添加成功');
						that.fetch()
						that.setState({'add_comment_modal': false})
					}else{
						message.error(data.msg);
						that.setState({
							'loading': false,
						})
					}
				},
				(err,data) => {
					if(err.response === 'Unauthorized.'){
						console.log('Token invalid');
						localStorage.removeItem('Authorization');
						that.setState({
							'redirect': '/login'
						})
						message.error('登录失效');
						return false;
					}
					message.error('Network error');
					that.setState({'loading': false})
				}
			);


		})
	}





	openFunctionModal = () => {
		this.setState({'edit_function_modal': true})
	}
	closeFunctionModal = () => {
		this.setState({'edit_function_modal': false})
	}
	editFunction = () => {
		let form =this.refs.add_comment;
		let that = this;
		form.validateFields().then((data) => {
			if(! data['gene_function']){
				message.error('请输入评论');
				return false;
			}

			reqwest({
				url: `/api/crog/gene/${that.state.gene}/function`,
				method: 'PATCH',
				headers: {
					'Authorization': that.state.token,
				},
				type: 'json',
				data: data
			}).then(
				(data) => {
					if(data.status === 1){
						message.success('添加成功');
						that.fetch()
						that.setState({'edit_function_modal': false})
					}else{
						message.error(data.msg);
						that.setState({
							'loading': false,
						})
					}
				},
				(err,data) => {
					if(err.response === 'Unauthorized.'){
						console.log('Token invalid');
						localStorage.removeItem('Authorization');
						that.setState({
							'redirect': '/login'
						})
						message.error('登录失效');
						return false;
					}
					message.error('Network error');
					that.setState({'loading': false})
				}
			);


		})
	}

	changeState = (value) => {
		reqwest({
			url: `/api/crog/gene/${this.state.gene}/status`,
			method: 'PATCH',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {'status': value}
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('修改成功');
					this.fetch()
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}
	
	
	render(){
		if(! this.state.token){
			return <Redirect to="/login"/>
		}
		if(this.state.redirect){
			return <Redirect to={this.state.redirect}/>
		}
		return (
			<div>
				<Header {...this.state} />
				<div className="max-width">
					<Nav L2={this.state.L2} />
					<Breadcrumb style={{'marginTop':'9px'}}>
						<Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
						<Breadcrumb.Item><Link to={`/crog/genes`}>Genes</Link></Breadcrumb.Item>
						<Breadcrumb.Item>{this.state.gene}</Breadcrumb.Item>
					</Breadcrumb>
					<PageHeader title={this.state.gene} style={{padding:0,marginTop:10}} >
						<Descriptions size="small" column={3}  style={{'width':'800px'}}>
	        				<Descriptions.Item label="创建时间">{this.state.gene_info['date_created']} </Descriptions.Item>
	        				<Descriptions.Item label="最后修改">{this.state.gene_info['date_last_update']} </Descriptions.Item>
	        				<Descriptions.Item label="状态">
	        					<Select bordered={false} 
	        						size="small"
	        						onChange={this.changeState}
	        						value={ this.state.gene_info['status'] ? this.state.gene_info['status'].toString() : ''}
	        						style={{'width':'100px'}}
	        					>
	        						<Select.Option value="1">未校对</Select.Option>
	        						<Select.Option value="2">校对中</Select.Option>
	        						<Select.Option value="3">完成校对</Select.Option>
	        						<Select.Option value="4">评审通过</Select.Option>
	        						<Select.Option value="9">已废弃</Select.Option>
	        					</Select>
	        				</Descriptions.Item>
	        			</Descriptions>
					</PageHeader>

					<PageHeader title="基本信息" style={{paddingLeft:0,paddingRight:0}} >
						<Descriptions size="small" column={3} bordered className="table_list2">
	        				<Descriptions.Item label="Full name">{this.state.gene_info['fullname']} </Descriptions.Item>
	        				<Descriptions.Item label="Type">{this.state.gene_info['type']} </Descriptions.Item>
	        				<Descriptions.Item label="NCBI entrez ID">{this.state.gene_info['entrez_gene_id']} </Descriptions.Item>
	        				<Descriptions.Item label="OMIM gene ID">{this.state.gene_info['OMIM_gene_id'] ? this.state.gene_info['OMIM_gene_id'].join(',') : ''} </Descriptions.Item>
	        				<Descriptions.Item label="HGNC gene ID">{this.state.gene_info['hgnc_id']} </Descriptions.Item>
	        				<Descriptions.Item label="pLI">{this.state.gene_info['pLI']} </Descriptions.Item>
	        				<Descriptions.Item label="HI">{this.state.gene_info['HI']} </Descriptions.Item>
	        			</Descriptions>
	        		</PageHeader>
					<PageHeader title="基因别名" style={{paddingLeft:0,paddingRight:0}} >
						{
							this.state.gene_info['alias'] ?  this.state.gene_info['alias'].map((item,index) => {
								return <Tag key={item} >{item}  <CloseOutlined onClick={e => this.removeTag(item)} /></Tag>
							}) : ''
						}
						
						{
							this.state.alias_input ? (
								<Input size="small" className="tag-input" ref="alias_input" onPressEnter={this.addNewAlias}  onBlur={this.clearInput} />
							) : (
								<Tag className="site-tag-plus" onClick={this.showInput}>
									<PlusOutlined /> New alias
								</Tag>
							)
						}
								
	        		</PageHeader>
					<PageHeader title="基因和疾病" style={{paddingLeft:0,paddingRight:0}} 
						extra={[
							<Button key="1" type="primary" onClick={this.openAddDisease} >添加疾病</Button>,
						]}
					>
						<Table 
							bordered 
							loading={this.state.loading} 
							rowKey="record_id" 
							size="small" 
							columns={this.state.disease_column} 
							dataSource={this.state.diseases}
						    locale={{emptyText:'No Data',}}
							pagination={false}
						/>
	        		</PageHeader>
					<PageHeader title="ClinGen" style={{paddingLeft:0,paddingRight:0}} >
						<Descriptions size="small" column={1} bordered className="table_list2">
	        				<Descriptions.Item label="Haploinsufficiency"> {this.state.gene_info['clingen_HI']} </Descriptions.Item>
	        				<Descriptions.Item label="Triplosensitivity">{this.state.gene_info['clingen_TS']}</Descriptions.Item>
	        			</Descriptions>
	        		</PageHeader>
	        		<PageHeader title="转录本" style={{paddingLeft:0,paddingRight:0}} >
						<Table 
							bordered 
							loading={this.state.loading} 
							rowKey="record_id" 
							size="small" 
							columns={transcript_column} 
							dataSource={this.state.transcripts}
						    locale={{emptyText:'No Data',}}
							pagination={false}
						/>
						<div>经典转录本：
							{ 
								this.state.edit_canonical_transcript ? (
									<Space>
										<Select value={this.state.canonical_transcript} onChange={this.canonicalTranscriptChange} size="small" style={{width:180,marginTop:'5px'}}>
											{this.state.transcripts.map((item,index) => {
												return <Select.Option key={index} value={item['transcript_id']}>{item['transcript_id']}</Select.Option>
											})}
										</Select>
										<Button size="small" style={{'marginTop': '4px'}} onClick={this.postCanonicalTranscriptChange}>保存</Button>
										<Button size="small" style={{'marginTop': '4px'}} onClick={this.closeTranscriptInput}>取消</Button>
									</Space>
								): (
									<span>{this.state.gene_info['canonical_transcript']}
									<Button type="link" onClick={this.showTranscriptInput}>修改</Button></span>
								)
							}
						</div>
	        		</PageHeader>
	        		<PageHeader title="HPO" style={{paddingLeft:0,paddingRight:0}} 
	        			extra={[
							<Select key="1" value={this.state.this_disease_id} onChange={this.changeDisease} style={{ width: 150 }}>{
								this.state.diseases.map((item,index) => {
									return <Select.Option key={index} value={item['disease_id']}>{item['disease_id']} </Select.Option>
								})
							}</Select>,
						]}
	        		>
						<Table 
							bordered 
							loading={this.state.loading} 
							rowKey="id" 
							size="small" 
							columns={hpo_column} 
							dataSource={this.state.hpos}
						    locale={{emptyText:'No Data',}}
							pagination={false}
						/>
	        		</PageHeader>
	        		<PageHeader title="OMIM description" style={{paddingLeft:0,paddingRight:0}} >
	        			<p>{this.state.gene_info['OMIM_gene_desc'] ? this.state.gene_info['OMIM_gene_desc'] : '无' }</p>
	        		</PageHeader>
	        		<PageHeader title="GHR description" style={{paddingLeft:0,paddingRight:0}} >
	        			<p>{this.state.gene_info['GHR_gene_desc'] ? this.state.gene_info['GHR_gene_desc'] : '无' }</p>
	        		</PageHeader>

	        		<PageHeader title="基因功能" style={{paddingLeft:0,paddingRight:0}} 
	        			extra={[
							<Button key="1" type="primary" onClick={this.openFunctionModal} >修改</Button>,
						]}
	        		>
	        			<p>{this.state.gene_info['gene_function'] ? this.state.gene_info['gene_function'] : '无' }</p>
	        		</PageHeader>

	        		<PageHeader title="Comments" style={{paddingLeft:0,paddingRight:0}} 
	        			extra={[
							<Button key="1" type="primary" onClick={this.openCommentModal} >添加评论</Button>,
						]}
	        		>
	        			{
	        				this.state.gene_info['comments'] && this.state.gene_info['comments'].map((item,index) => {
	        					return <div style={{border:'1px solid #f0f0f0','paddingRight':'16px','paddingLeft':'16px'}}>
	        						<Comment content={item['comment']} author={item['email']} datetime={item['date']} avatar={<UserOutlined />}  />
	        					</div>
	        				})
	        			}
	        		</PageHeader>

				</div>
				<Modal
		        	title={<BuildTitle visible={this.state.add_comment_modal} id="add_comment_modal" title="添加评论" />}
					visible={this.state.add_comment_modal}
					onCancel={this.closeCommentModal}
					onOk={this.addComment}
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('add_comment_modal').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="add_comment"
						layout="horizontal"
						initialValues={{}}
						labelCol = {{ span: 4 }}
						wrapperCol = {{ span: 24 }}
						labelAlign="left"
					>
						<Form.Item name="comment" label="" rules={[{ required: true }]}>
							<Input.TextArea rows={5} />
						</Form.Item>
					</Form>
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.edit_function_modal} id="edit_function_modal" title="修改基因功能" />}
					visible={this.state.edit_function_modal}
					onCancel={this.closeFunctionModal}
					onOk={this.editFunction}
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('edit_function_modal').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="add_comment"
						layout="horizontal"
						initialValues={{'gene_function': this.state.gene_info['gene_function']}}
						labelCol = {{ span: 4 }}
						wrapperCol = {{ span: 24 }}
						labelAlign="left"
					>
						<Form.Item name="gene_function" label="" rules={[{ required: true }]}>
							<Input.TextArea rows={5} />
						</Form.Item>
					</Form>
				</Modal>
				<Footer />
			</div>
		)
	}
}